import { ExceptionOutlined, PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Table, Typography } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { _ } from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { DateInput } from '../../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { landscapePageStyle } from '../../ledgers/pages/ClientLedgers';
import {
  useJourneyDateWiseDueQuery,
  useLazyJourneyDateWiseExcelQuery,
} from '../Endpoint/AirTicketReport.endpoint';
import { JourneyDateWiseDueColumn } from '../Utils/JourneyDateWiseDueColumn';

type Props = {};

const JourneyDateWiseDue = (props: Props) => {
  const [form] = useForm();
  const tableRef = useRef(null);
  const date_range = useWatch('date_range', form);
  const client = useWatch('client', form);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const [downloadExcelRep] = useLazyJourneyDateWiseExcelQuery();

  const { data, refetch, isFetching, isLoading } = useJourneyDateWiseDueQuery({
    ...pagination,
    formDate: _.isArray(date_range) && dayjs(date_range[0]).format('YYYY-MM-DD'),
    todDate: _.isArray(date_range) && dayjs(date_range[1]).format('YYYY-MM-DD'),

    client: client,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  useEffect(() => {
    form.setFieldsValue({ client: 'all' });
    form.setFieldsValue({ date_range: [dayjs(), dayjs()] });
  }, []);

  const onFinish = () => {
    refetch();
  };

  const downloadPdf = useReactToPrint({
    content: () => tableRef.current,
    pageStyle: landscapePageStyle,
    documentTitle: `AirTicket Details`,
  });

  const downloadExcel = () => {
    downloadExcelRep({
      formDate: _.isArray(date_range) && dayjs(date_range[0]).format('YYYY-MM-DD'),
      todDate: _.isArray(date_range) && dayjs(date_range[1]).format('YYYY-MM-DD'),
      excelName: `Journey_Date_Wise_Due_Report_from_date=${dayjs(date_range[0]).format(
        'DD-MM-YYYY'
      )}_to_date=${dayjs(date_range[1]).format('DD-MM-YYYY')}`,
    });
  };

  const jsx_com = (
    <>
      <Typography.Text style={{ margin: '5px 0px' }}>
        You have total: {data?.count || 0} rows
      </Typography.Text>
      <Table
        rowClassName={(record) =>
          Number(record?.invoice_net_total) === Number(record?.invclientpayment_amount)
            ? 'success-row-style'
            : ''
        }
        style={{ width: '100%' }}
        bordered
        size='small'
        dataSource={data?.data}
        tableLayout='fixed'
        columns={JourneyDateWiseDueColumn({ pagination })}
        loading={{
          spinning: isLoading || isFetching,
          indicator: <LoadingIndicator />,
        }}
        scroll={{ x: 'auto' }}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />
    </>
  );

  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={tableRef}
        title={{
          info_title: '',
          title: 'Ticket Wise Details',
        }}
        extraInfo={{
          date: date_range
            ? `${dayjs(date_range[0]).format('DD-MM-YYYY')} to ${dayjs(date_range[1]).format(
                'DD-MM-YYYY'
              )}`
            : '',
        }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Others', 'Journey Date Wise Due']} />

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row justify={'space-between'}>
          <Col sm={24} md={12} lg={12}>
            <div style={{ display: 'flex', gap: 10 }}>
              <SelectClients
                name={'client'}
                placeholder='Select Client'
                label=''
                showAll={true}
                offDropDown
              />
              <DateInput name='date_range' size={7} rangePicker />

              <Button type='primary' htmlType='submit' icon={<SearchOutlined />}>
                Search
              </Button>
            </div>
          </Col>

          <Col style={{ display: 'flex', gap: 10 }}>
            <Button type='primary' onClick={downloadPdf}>
              <PrinterOutlined />
              Print
            </Button>
            <Button onClick={downloadExcel} type='primary'>
              <ExceptionOutlined />
              Excel
            </Button>
          </Col>
        </Row>
      </Form>

      {jsx_com}

      {print_content}
    </>
  );
};

export default JourneyDateWiseDue;
