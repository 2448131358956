import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useReactToPrint } from "react-to-print";
import { Fixed2, rangePresets } from "../../../../common/utils/common.utils";
import { FormButton } from "../../../../components/common/FormItem/FormItems";
import { SelectClients } from "../../../../components/common/FormItem/SelectCustomFeilds";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import {
  useGetClientWiseCollect_SalesbyIDQuery,
  useLazyGetExcelsClinetWiseCollandSalesQuery,
} from "../endpoints/clientWiseCollectionAndSalesEndpoints";
import { IClientWiseCollectionAndSalesReportFormData } from "../types/clientWiseCollectionAndSalesReportTypes";
import { ClientWiseCollectionUtils } from "../utils/ClientWiseCollectionAndSalesUtils";
import OnlySalesReport from "./OnlySalesReport";

const ClientWiseCollectionAndSales = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const location = useLocation();
  const [date, setDate] = useState<any>([dayjs(), dayjs()]);
  const [fetchExcel] = useLazyGetExcelsClinetWiseCollandSalesQuery();
  const [client, setClient] = useState("all");
  const [getData, SetGetData] = useState(true);
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: "",
    query: "",
  });

  useEffect(() => {
    form.setFieldValue("date_range", [dayjs(), dayjs()]);
    form.setFieldValue("invoice_combclient_id", "all");
  }, []);

  // This useEffect is working based on Params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dailyValue = searchParams.get("daily");

    const monthFormDate = searchParams.get("from_date");
    const monthToDate = searchParams.get("to_date");

    const yearFormDate = searchParams.get("y_from_date");
    const yearToDate = searchParams.get("y_to_date");

    if (dailyValue) {
      setDate([dayjs(dailyValue), dayjs(dailyValue)]);
      onFinish({
        date_range: [dailyValue, dailyValue],
        invoice_combclient_id: "all",
      });
    }

    if (monthFormDate && monthToDate) {
      setDate([dayjs(monthFormDate), dayjs(monthToDate)]);
      form.setFieldValue("date_range", [dayjs(monthFormDate), dayjs(monthToDate)]);
      onFinish({
        date_range: [monthFormDate, monthToDate],
        invoice_combclient_id: "all",
      });
    }

    if (yearFormDate && yearToDate) {
      setDate([dayjs(yearFormDate), dayjs(yearToDate)]);
      form.setFieldValue("date_range", [dayjs(yearFormDate), dayjs(yearToDate)]);
      onFinish({
        date_range: [yearFormDate, yearToDate],
        invoice_combclient_id: "all",
      });
    }
  }, [location.search]);

  // Handle Pagination For Collection
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  // API Call
  const { isError, isFetching, isLoading, data } = useGetClientWiseCollect_SalesbyIDQuery(
    {
      client_id: client,
      from: dayjs(date[0]).format("YYYY-MM-DD"),
      to: dayjs(date[1]).format("YYYY-MM-DD"),
      page: pagination.current,
      size: pagination.pageSize,
    },
    { skip: getData }
  );

  let collectionTotal: number = Number(data?.data?.collection?.receipt_total_amount || 0);

  const onFinish = async (values: IClientWiseCollectionAndSalesReportFormData) => {
    setDate(values.date_range);
    setClient(values.invoice_combclient_id || "all");
    SetGetData(false);

    setExcelQuery({
      Id: values.invoice_combclient_id.toString(),
      query: `?from_date=${dayjs(values.date_range[0]).format("YYYY-MM-DD")}&to_date=${dayjs(
        values.date_range[1]
      ).format("YYYY-MM-DD")}&page=${pagination.current}&size=${pagination.pageSize}`,
    });
  };

  const CollectionLastPage = Math.ceil(
    Number(data?.data?.collection_count || 0) / (pagination?.pageSize || 0)
  );

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "",
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <>
        <FormHeaderTitle title="Collection" />

        <Table
          bordered
          dataSource={data?.data?.collection?.collection_data}
          columns={ClientWiseCollectionUtils(pagination)}
          size="small"
          loading={{
            spinning: isLoading || isFetching,
            indicator: loadingIndicator,
          }}
          pagination={
            data?.data?.collection_count !== undefined && data?.data?.collection_count < 20
              ? false
              : {
                  ...pagination,
                  total: data?.data?.collection_count,
                  showSizeChanger: true,
                  pageSizeOptions: ["20", "50", "100", "200", "500"],
                  onChange: handlePaginationChange,
                }
          }
          summary={(_) => {
            if (CollectionLastPage === pagination.current)
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={5}>
                      <div
                        style={{
                          color: "green",
                          fontWeight: "700",
                          textAlign: "right",
                        }}
                      >
                        <Typography.Text strong>Total: </Typography.Text>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right">
                      <Typography
                        style={{
                          textAlign: "right",
                          marginRight: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {Fixed2(collectionTotal)}
                      </Typography>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
          }}
        />
      </>

      <OnlySalesReport date={date} client={client} />
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{
          info_title: "",
          title: "Client Wise Collection & Sales Report",
        }}
        extraInfo={{
          date:
            `${dayjs(date[0]).format("DD-MM-YYYY")} to ${dayjs(date[1]).format("DD-MM-YYYY")}` ||
            "",
        }}
      />
    </div>
  );
  return (
    <>
      {!id && <BreadCrumb arrOfOption={["Report", "Client Wise Collection & Sales Report"]} />}
      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type="primary"
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: `sales/clientWiseCollectionSalesReport?from_date=${dayjs(
                date[0]
              ).format("YYYY-MM-DD")}&to_date=${dayjs(date[1]).format("YYYY-MM-DD")}`,
              excelName: `Client_Wise_Collection_Sales_Report_from_date=${dayjs(date[0]).format(
                "DD-MM-YYYY"
              )}_to_date=${dayjs(date[1]).format("DD-MM-YYYY")}`,
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Row justify={"space-between"}>
        <Col>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <div style={{ display: "flex" }}>
                <SelectClients
                  name="invoice_combclient_id"
                  label="Select Client"
                  placeholder="Select client"
                  size={8}
                  showAll
                  offDropDown
                />
                <Col span={6} xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    label="Date Range"
                    name="date_range"
                    rules={[{ required: true, message: ` Date Range is required!` }]}
                  >
                    <DatePicker.RangePicker
                      presets={rangePresets}
                      format={"DD-MM-YYYY"}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <FormButton label="Search" icon textAlign="left" loading={isLoading} />
              </div>
            </Row>
          </Form>
        </Col>
      </Row>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default ClientWiseCollectionAndSales;
