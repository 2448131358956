import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getSessionId } from '../..';
import { api } from '../../app/baseQuery';
import { RootState } from '../../app/store';
import assyncWrapper from '../../common/utils/assyncWrapper';
import lib from '../utils/lib';

export type TUser = {
  user_id: number;
  user_full_name: string;
  user_agency_id: number;
  user_role: 'DEV_ADMIN' | 'SUPER_ADMIN' | 'EMPLOYEE' | 'DEACTIVATE';
  role_name: string;
  role_permissions: string;
  modules: moduleType[];
  branches: IBranchInfo[];
  organization_info: IOrgInfo;
  role_user_type: 'ADMIN' | 'EMPLOYEE' | 'ACCOUNTS';
  remaining_days: number;
};

export interface IOrgInfo {
  org_name: string;
  org_owner_email: string;
  org_logo: string;
  org_address1: string;
  org_address2: string;
  org_address3: string;
  org_mobile: string;
  org_extra_info: string;
  org_facebook: string;
  org_website: string;
  org_logo_height: number;
  org_currency: string;
  org_module_type: 'REC' | 'TRABILL';
}
export interface IBranchInfo {
  org_id: number;
  org_name: string;
}
export type moduleType =
  | 'dashboard'
  | 'invoice_airticket'
  | 'invoice_non_commission'
  | 'invoice_reissue'
  | 'invoice_other'
  | 'invoice_other_package'
  | 'invoice_visa'
  | 'visa_process'
  | 'invoice_tour_package'
  | 'invoice_hajj_pre_reg'
  | 'invoice_hajj'
  | 'invoice_ummrah'
  | 'hajji_management'
  | 'refund_module'
  | 'money_receipt'
  | 'accounts_module'
  | 'cheque_management'
  | 'payroll'
  | 'expense'
  | 'loan_management_module'
  | 'sms_system'
  | 'clients'
  | 'combined_clients'
  | 'vendors'
  | 'agents'
  | 'quotation'
  | 'passport_management'
  | 'report_module'
  | 'database_backup'
  | 'recruitment';

export type TState = TUser | null;

type TReducers = {
  logout(state: TState): void;
  setUser(state: TState, action: PayloadAction<TUser>): void;
};

let initialState: TState = null;

const userSlice = createSlice<TState, TReducers, 'user'>({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<TUser>) => {
      state = action.payload;
      return state;
    },

    logout(state) {
      api.util.resetApiState();
      lib.removeLocalStorageItem('user');
      lib.removeLocalStorageItem('__tus');
      lib.removeLocalStorageItem('__branch_id');

      if (state) {
        assyncWrapper(async () => {
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v1/auth/logout?session_id=${getSessionId()}`,
            {
              method: 'POST',
            }
          );
        });
      }
      localStorage.removeItem('agency_id');
      return null;
    },
  },

  extraReducers(builder) {
    builder.addMatcher(api.endpoints.login.matchFulfilled, (_state, action) => {
      return action.payload;
    });

    builder.addMatcher(api.endpoints.getInitialToken.matchFulfilled, (_state, action) => {
      return action.payload;
    });
  },
});

export const { logout, setUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;

export interface IRcmType {
  trd_recruit_tenant_id: number;
  trd_recruit_user_id: number;
  trd_recruit_token: string;
}
