import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, ConfigProvider, DatePicker, Form, Row, Space, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { Fixed2, TableParams, handleTableChange } from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { SelectVendorsOnly } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { disabledFeatureDate } from '../../../../helpers/CapitalizeFirstLetter';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import { useLazyGetAllDueAdvanceVendorsQuery } from '../endpoints/total_due_advance_vendorEndpoints';
import { ITotal_Due_Advance_FormData } from '../types/Total_Due_Advance_VendorTypes';
import { Total_Due_Advance_VendorUtils } from '../utils/Total_Due_Advance_VendorUtils';
type Props = {};

function Total_Due_Advance_Vendor({}: Props) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });

  const [fetchTotalDueAdvance, { data, isLoading }] = useLazyGetAllDueAdvanceVendorsQuery();

  const todayDate = dayjs().format('YYYY-MM-DD');
  const onFinish = async (values: ITotal_Due_Advance_FormData) => {
    const setFrom_date = dayjs(values.payment_date).format('YYYY-MM-DD');
    setDate(setFrom_date);
    const date_range = `payment_date=${setFrom_date}`;
    const body = {
      vendor_id: values.vendorId,
      date_range: date_range,
    };
    setDate_range_value(date_range);
    fetchTotalDueAdvance({
      vendor_id: body.vendor_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      Id: body.vendor_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };
  useEffect(() => {
    form.setFieldValue('vendorId', 'all');
    const date_range = `payment_date=${todayDate}&to_date=${todayDate}`;
    const body = {
      vendor_id: 'all',
      date_range: date_range,
    };
    fetchTotalDueAdvance({
      vendor_id: body.vendor_id,
      query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: 'all',
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Due_Advance_Report_${date_range_value}`,
  });

  // @PAGINATION--START-------
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100', '200', '500'],
      pageSize: 20,
      total: data?.count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: data?.count },
    }));
  }, [data?.count]);

  useEffect(() => {
    form.setFieldValue('payment_date', dayjs());
  }, []);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: ITotal_Due_Advance_FormData = form.getFieldsValue();
    const setTo_date = dayjs(values.payment_date).format('YYYY-MM-DD');
    const date_range = `payment_date=${setTo_date}`;
    const body = {
      vendor_id: values.vendorId == 'all' ? values.vendorId : values.vendorId.split('-')[1],

      payment_date: date_range,
    };

    fetchTotalDueAdvance({
      vendor_id: body.vendor_id,
      query: `${query}&${body.payment_date}`,
    });
    setExcelQuery({
      Id: body.vendor_id.toString(),
      query: `${query}&${body.payment_date}`,
    });
  };

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <FormHeaderTitle title='Total Due/Advance (Vendor)' />
      <ConfigProvider>
        <Typography.Text style={{ margin: 10 }}>You have total: {data?.count} rows</Typography.Text>
        <Table
          size='small'
          bordered
          pagination={
            data?.count !== undefined && data?.count > 20 ? tableParams.pagination : false
          }
          dataSource={data?.data}
          columns={Total_Due_Advance_VendorUtils(queryData)}
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
          onChange={(args1, args2, args3) =>
            handleTableChange({
              args: {
                pagination: args1,
                filters: args2,
                sorter: args3,
              },
              states: {
                refetch,
                setQueryData,
                setTableParams,
                tableParams,
              },
            })
          }
          summary={(tableData) => {
            let totalAdvance = 0;
            let totalDue = 0;

            tableData.map(({ vtrxn_lbalance }) => {
              const balance = Number(vtrxn_lbalance);
              if (balance > 0) {
                totalAdvance += balance;
              } else if (balance < 0) {
                totalDue += balance;
              }
            });
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={4}>
                  <Typography.Text strong>Total Due & Advanced</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <div
                    style={{
                      color: 'red',
                      textAlign: 'right',
                    }}
                  >
                    {Fixed2(Math.abs(totalDue))}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <Typography.Text strong>
                    <div
                      style={{
                        color: 'green',
                        textAlign: 'right',
                      }}
                    >
                      {Fixed2(totalAdvance)}
                    </div>
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  {
                    <Typography.Text
                      strong
                      style={{
                        color: totalAdvance + totalDue > 0 ? 'green' : 'red',
                      }}
                    >
                      {Math.abs(totalAdvance + totalDue)}
                    </Typography.Text>
                  }
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </ConfigProvider>
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Total Due/Advance (Vendor)' }}
        extraInfo={{
          date: dayjs(date_range_value?.split('=')[1]).format('DD-MM-YYYY') || '',
        }}
      />
    </div>
  );

  return (
    <div>
      <BreadCrumb arrOfOption={['Report', 'Total Due/Advance (Vendor)']} />

      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            if (data?.count)
              fetchExcel({
                client_id: excelQuery.Id,
                query: excelQuery.query,
                excelApiName: 'totalDueAdvance/vendor',
                excelName: `total_due_advance_vendor_from_date=${dayjs(date).format(
                  'DD-MM-YYYY'
                )}_to_date=${dayjs(date).format('DD-MM-YYYY')}`,
              });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <SelectVendorsOnly
            required
            name='vendorId'
            size={5}
            label='Select Vendor'
            placeholder='Select Vendor'
            showAll
            offDropDown
          />
          <Col span={6} xs={24} sm={24} md={24} lg={4}>
            <Form.Item
              label='Select Date'
              name='payment_date'
              rules={[{ required: true, message: ` Date is required!` }]}
            >
              <DatePicker
                format={'DD-MM-YYYY'}
                allowClear={false}
                style={{ width: '100%' }}
                disabledDate={disabledFeatureDate}
              />
            </Form.Item>
          </Col>
          <Col lg={2}>
            <FormButton label='Search' icon textAlign='left' loading={isLoading} />
          </Col>
        </Row>
      </Form>
      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </div>
  );
}

export default Total_Due_Advance_Vendor;
