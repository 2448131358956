import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Form, Space, Table } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { pagination } from "../../../../common/utils/common.utils";
import ReportsHeader from "../../../../components/common/ReportsHeader";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import { useLazyGetGDSReportQuery } from "../endpoints/GdsReportEndpoints";
import { IGDSReportFromDataType, IGDSReportsDataType } from "../types/GdsReportType";
import gdsReportColumn from "../utils/gdsReportColumn";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import { useLazyGetExcelQuery } from "../../ledgers/endpoints/ledgerEndpoints";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import { excelDateRangeFormat } from "../../../../common/utils/excelDateRangeFormat";

const GdsReport = () => {
  const [paramData, setParamData] = useState<{
    date_range: string;
    id: number | string;
  }>();
  const currentDate = dayjs().format("DD-MM-YYYY");
  const [dateRange, setDateRange] = useState(`from_date=${currentDate}&to_date=${currentDate}`);
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: "",
    query: "",
  });

  const [fetchGDSReport, { data, isLoading }] = useLazyGetGDSReportQuery({
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const dataToReturn: IGDSReportsDataType[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return {
        ...cache,
        data: dataToReturn,
      };
    },
  });
  const onFinish = async (values: IGDSReportFromDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    setDateRange(date_range);
    const body = {
      date_range: date_range,
      gds: values.gds,
    };
    await fetchGDSReport(body);

    setExcelQuery({
      Id: body.gds.toString(),
      query: `?${body.date_range}`,
    });

    setParamData({
      date_range: date_range,
      id: "",
    });
  };
  const to_date = dayjs().format("YYYY-MM-DD");
  useEffect(() => {
    form.setFieldValue("date_range", [dayjs(), dayjs()]);
    const date_range = `from_date=${to_date}&to_date=${to_date}`;
    form.setFieldValue("gds", "all");
    const body = {
      date_range: date_range,
      gds: "all",
    };
    fetchGDSReport(body);
    setExcelQuery({
      Id: "all",
      query: `?${body.date_range}`,
    });
    setParamData({
      date_range: date_range,
      id: "",
    });
  }, []);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `GDS_Report`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <Table
      size="small"
      bordered
      pagination={pagination(data.length)}
      columns={gdsReportColumn(paramData)}
      dataSource={data}
      loading={{ spinning: isLoading, indicator: loadingIndicator }}
    />
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "GDS Report" }}
        extraInfo={{
          date:
            paramData?.date_range
              ?.split("&")
              ?.map((param) => dayjs(param.split("=")[1])?.format("DD-MM-YYYY"))
              ?.join(" to ") || "",
        }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb arrOfOption={["Reports", "GDS Report"]} />
      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type="primary"
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: "gdsReport",
              excelName: `gds_report_${excelDateRangeFormat(dateRange as string)}`,
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <ReportsHeader
          date_range
          required
          reports_select
          showGds
          reports_title="GDS Report"
          showAll
        />
      </Form>

      <FormHeaderTitle title="GDS Report" />

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default GdsReport;
