import React, { useState } from "react";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { Button } from "antd";
import dayjs from "dayjs";
import { FileTextOutlined } from "@ant-design/icons";

type Props<T extends Record<string, unknown>> = {
  excelName: string;
  excelTableHead: (keyof T)[];
  excelData: T[];
};

function ExcelDownload<T extends Record<string, unknown>>({
  excelName,
  excelTableHead,
  excelData,
}: Props<T>): React.ReactElement {
  const [loading, setLoading] = useState<boolean>(false);

  const saveExcel = async () => {
    setLoading(true);
    try {
      const workbook: Excel.Workbook = new Excel.Workbook();
      const worksheet: Excel.Worksheet = workbook.addWorksheet(excelName);

      const mergeRange: string = `A1:${String.fromCharCode(65 + excelTableHead.length - 1)}3`;

      worksheet.mergeCells(mergeRange);
      const combinedHeader: Excel.Cell = worksheet.getCell("A1");
      combinedHeader.value = excelName;
      combinedHeader.font = { bold: true, size: 16 };
      combinedHeader.alignment = { vertical: "middle", horizontal: "center" };
      combinedHeader.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const headerRow: Excel.Row = worksheet.addRow(excelTableHead as string[]);
      headerRow.eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { horizontal: "center" };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "d9d9d9" },
        };

        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      excelData.forEach((rowData) => {
        const values = excelTableHead.map((header) => rowData[header]);
        const row: Excel.Row = worksheet.addRow(values);
        row.height = 25;
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "left" };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      worksheet.columns.forEach((column) => {
        let maxLength = 0;
        if (column && column.eachCell) {
          column.eachCell({ includeEmpty: true }, (cell) => {
            const columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength) {
              maxLength = columnLength;
            }
          });
          column.width = Math.min(maxLength + 2, 50); // Set width with a maximum of 50
        }
      });

      const excelDataGenerate: Excel.Buffer = await workbook.xlsx.writeBuffer();

      saveAs(new Blob([excelDataGenerate]), `${excelName}.xlsx`);
    } catch (error: unknown) {
      console.error("Something Went Wrong", (error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button type="primary" icon={<FileTextOutlined />} onClick={saveExcel} loading={loading}>
      Excel
    </Button>
  );
}

export default ExcelDownload;
