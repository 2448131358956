import { Table, Typography } from 'antd';
import styled from 'styled-components';
import {
  Fixed2,
  getTableColumnsWithoutNull,
  pagination,
} from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { BalanceStatus, IBalanceStatus } from '../../AccountsTypes/AccountsTypes';
import { useGetAllBalanceStatusQuery } from '../../Api/Endpoints/balanceStatusEndpoints';
import { ListOfBalanceStatusColumns } from '../../utils/Balance_Status/ListOfBalanceStatusColumns';
import { useState } from 'react';

type Props = {};

const Balance_Status = (props: Props) => {
  const {
    isLoading,
    data: balanceStatus,
    currentData,
    refetch,
  } = useGetAllBalanceStatusQuery(undefined, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;

      const cash_accTypeOne: BalanceStatus[] = [];
      const bank_accTypeTwo: BalanceStatus[] = [];
      const mobile_accTypeThree: BalanceStatus[] = [];

      if (data) {
        for (let i = 0; i < data.accTypeOne.length; i++) {
          const element = data.accTypeOne[i];

          cash_accTypeOne.push({
            ...element,
            key: i + 1,
          });
        }

        for (let i = 0; i < data.accTypeTwo.length; i++) {
          const element = data.accTypeTwo[i];

          bank_accTypeTwo.push({
            ...element,
            key: i + 1,
          });
        }

        for (let i = 0; i < data.accTypeThree.length; i++) {
          const element = data.accTypeThree[i];

          mobile_accTypeThree.push({
            ...element,
            key: i + 1,
          });
        }
      }

      const dataToReturn: IBalanceStatus = {
        accTypeOne: cash_accTypeOne,
        accTypeTwo: bank_accTypeTwo,
        accTypeThree: mobile_accTypeThree,
      };

      return { ...cache, data: dataToReturn };
    },
  });
  let cash_accBalance_amount = 0;
  let bank_accBalance_amount = 0;
  let mobile_accBalance_amount = 0;
  balanceStatus.accTypeOne.forEach(({ accbalance_amount }) => {
    cash_accBalance_amount += Number(accbalance_amount);
  });
  balanceStatus.accTypeTwo.forEach(({ accbalance_amount }) => {
    bank_accBalance_amount += Number(accbalance_amount);
  });
  balanceStatus.accTypeThree.forEach(({ accbalance_amount }) => {
    mobile_accBalance_amount += Number(accbalance_amount);
  });
  const net_total =
    Number(cash_accBalance_amount || 0) +
    Number(bank_accBalance_amount || 0) +
    Number(mobile_accBalance_amount || 0);

  const columns = ListOfBalanceStatusColumns();

  const filteredColumns = getTableColumnsWithoutNull(balanceStatus.accTypeOne, columns);

  return (
    <Style>
      <BreadCrumb
        arrOfOption={['Accounts', 'Balance Status']}
        refetch={refetch}
        reloaderSize='small'
      />

      <FormHeaderTitle title='Cash' />
      <Table
        size='small'
        bordered
        pagination={false}
        columns={filteredColumns}
        dataSource={balanceStatus.accTypeOne}
        scroll={{ x: true }}
        summary={(_) => {
          return (
            <Table.Summary.Row style={{ background: '#C7C8CC', padding: 15 }}>
              <Table.Summary.Cell index={0}>
                <Typography.Text strong>Total</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <Typography.Text strong>{Fixed2(cash_accBalance_amount)}</Typography.Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />

      <FormHeaderTitle title='Bank' />

      <Table
        bordered
        size='small'
        pagination={false}
        columns={ListOfBalanceStatusColumns()}
        dataSource={balanceStatus.accTypeTwo}
        scroll={{ x: true }}
        summary={(_) => {
          return (
            <Table.Summary.Row style={{ background: '#C7C8CC', padding: 15 }}>
              <Table.Summary.Cell index={0}>
                <Typography.Text strong>Total</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <Typography.Text strong>{Fixed2(bank_accBalance_amount)}</Typography.Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      ></Table>

      <FormHeaderTitle title='Mobile Banking' />

      <Table
        bordered
        size='small'
        columns={ListOfBalanceStatusColumns()}
        pagination={false}
        dataSource={balanceStatus.accTypeThree}
        scroll={{ x: true }}
        summary={(_) => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <Typography.Text strong>Total</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <Typography.Text strong>{Fixed2(mobile_accBalance_amount)}</Typography.Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      ></Table>

      <Typography.Title
        level={4}
        style={{
          float: 'right',
          color: '#34495E',
          marginTop: 25,
          marginRight: '5%',
        }}
      >
        Total Balance :{net_total.toFixed(0)}
      </Typography.Title>

      {isLoading && <LoadingIndicator />}
    </Style>
  );
};

export default Balance_Status;
const Style = styled.div``;
