import dayjs from 'dayjs';
import { useLazyGetAllRefundReportClientQuery } from '../endpoints/refundReportEndpoints';
import {
  IRefundReportClientDataTypes,
  IRefundReportFormDataType,
} from '../types/refundReportTypes';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Radio, RadioChangeEvent, Space, Table, Typography } from 'antd';
import { useReactToPrint } from 'react-to-print';
import * as XLSX from 'xlsx';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import { RefundReportColumnClient } from '../utils/RefundReportColumn';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { excelDateRangeFormat } from '../../../../common/utils/excelDateRangeFormat';
const ClientRefund = ({
  setValue,
  value,
}: {
  setValue: React.Dispatch<React.SetStateAction<number>>;
  value: number;
}) => {
  const [fetchExcel] = useLazyGetExcelQuery();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [excelQuery, setExcelQuery] = useState<{
    query: string;
  }>({
    query: '',
  });
  //pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const [form] = Form.useForm();
  let totalTicketPrice: number = 0;
  let ctotalRefund: number = 0;
  let customerTotalAmount: number = 0;
  let ctotalReturn: number = 0;
  let totalNetProfitLoss: number = 0;
  const [fetchRefundReportClient, { data: client_data, isLoading, isFetching }] =
    useLazyGetAllRefundReportClientQuery();

  if (client_data?.data) {
    for (let i = 0; i < client_data?.data.length; i++) {
      totalTicketPrice += Number(client_data?.data[i].crefund_ticket_price);
      ctotalRefund += Number(client_data?.data[i].crefund_amount);
      customerTotalAmount += Number(client_data?.data[i].ccharge_amount);
      ctotalReturn += Number(client_data?.data[i].crefund_return_amount);
      totalNetProfitLoss +=
        Fixed2(client_data?.data[i].ccharge_amount) - Fixed2(client_data?.data[i].vendor_charge);
    }
  }

  const onFinish = async (values: IRefundReportFormDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    setDate_range_value(date_range);
    const body = {
      date_range: date_range,
      currentSize: pagination.current,
      currentPage: pagination.pageSize,
    };
    await fetchRefundReportClient({
      date_range: body.date_range,
      currentPage: body.currentPage,
      currentSize: body.currentSize,
    });
    setExcelQuery({
      query: `?${body.date_range}&page=${body.currentSize}&size=${body.currentPage}`,
    });
  };
  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);

    const date_range = `from_date=${dayjs().format('YYYY-MM-DD')}&to_date=${dayjs().format(
      'YYYY-MM-DD'
    )}`;
    const body = {
      date_range: date_range,
      currentSize: pagination.current,
      currentPage: pagination.pageSize,
    };
    fetchRefundReportClient({
      date_range: body.date_range,
      currentPage: body.currentPage,
      currentSize: body.currentSize,
    });
  }, []);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Refund Report`,
  });
  //Excel sheet Data
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const optionsSummaryReport = [
    { label: 'Client Report', value: 1 },
    { label: 'Vendor Report', value: 2 },
  ];

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <>
        <Table
          size='small'
          bordered
          dataSource={client_data?.data}
          columns={RefundReportColumnClient()}
          loading={{
            spinning: isLoading || isFetching,
            indicator: loadingIndicator,
          }}
          pagination={
            client_data?.count && client_data?.count > 20
              ? {
                  ...pagination,
                  total: client_data?.count,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '50', '100', '200', '500'],
                  onChange: handlePaginationChange,
                }
              : false
          }
          summary={(_) => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={1} colSpan={5}>
                    <div
                      style={{
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>Total: </Typography.Text>
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>{Fixed2(totalTicketPrice)}</Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <div
                      style={{
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>{Fixed2(customerTotalAmount)}</Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <div
                      style={{
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>{Fixed2(ctotalRefund)}</Typography.Text>
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>{Fixed2(ctotalReturn)}</Typography.Text>
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>{Fixed2(totalNetProfitLoss)}</Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </>
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Refund Report' }}
        extraInfo={{
          date:
            date_range_value
              ?.split('&')
              ?.map((param) => dayjs(param.split('=')[1])?.format('DD-MM-YYYY'))
              ?.join(' to ') || '',
        }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Refund Report']} />

      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: '',
              query: excelQuery.query,
              excelApiName: 'refundReportClient',
              excelName: `Refund_Report_Client_${excelDateRangeFormat(date_range_value as string)}`,
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
        <Radio.Group
          options={optionsSummaryReport}
          onChange={onChange}
          value={value}
          optionType='button'
          buttonStyle='solid'
        />
      </Space>

      <br />

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader date_range required reports_title='Client Refund Report' />
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default ClientRefund;
