import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Table } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { IPermission } from '../../../common/types/commonTypes';
import { rangePresets } from '../../../common/utils/common.utils';
import { loadingIndicator } from '../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetSMSByDateQuery } from '../Api/Endpoints/SMSEndpoints';
import { ISMSDateForm } from '../SMSTypes/SMSTypes';
import { SMSLogUtils } from '../Utils/SMSLogUtils';

type Props = { permission?: IPermission };
const { RangePicker } = DatePicker;

const SMSLog = (props: Props) => {
  const [form] = Form.useForm();

  const [fromDate, setFromDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    showSizeChanger: true,
    pageSizeOptions: ['20', '50', '100', '200', '500'],
  });

  const [fetchSms, { isLoading, isFetching, data: smsLogs }] = useLazyGetSMSByDateQuery();

  useEffect(() => {
    setCount(smsLogs?.total || 0);
  }, [smsLogs]);

  useEffect(() => {
    const query = `?from_date=${fromDate}&to_date=${toDate}&page=${pagination.current}&size=${pagination.pageSize}`;
    fetchSms({ query });
  }, [pagination.current, pagination.pageSize]);

  const onFinish = (values: ISMSDateForm) => {
    const fromDate = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const toDate = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    setFromDate(fromDate);
    setToDate(toDate);
    const query = `?from_date=${fromDate}&to_date=${toDate}&page=${pagination.current}&size=${pagination.pageSize}`;
    fetchSms({ query });
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  return (
    <div>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <Row gutter={[10, 10]}>
            <Col>
              <Form.Item label={false} name='date_range'>
                <RangePicker
                  defaultValue={[dayjs(), dayjs()]}
                  presets={rangePresets}
                  allowClear={false}
                  format={'DD-MM-YYYY'}
                />
              </Form.Item>
            </Col>
            <Col>
              <Button icon={<SearchOutlined />} htmlType='submit' type='primary'>
                Get Log
              </Button>
            </Col>
          </Row>
        </Col>
      </Form>

      <FormHeaderTitle title='SMS Logs' />
      <Table
        size='small'
        bordered
        dataSource={smsLogs?.data}
        columns={SMSLogUtils()}
        scroll={{ x: true }}
        loading={{ spinning: isLoading || isFetching, indicator: loadingIndicator }}
        pagination={count > 20 ? { ...pagination, total: count } : false}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default SMSLog;
