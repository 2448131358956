import { Select } from 'antd';
import { api } from '../../app/baseQuery';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectUser } from '../../auth/states/userSlice';
import { IBranchInfo } from './../../auth/states/userSlice';
import { setBranch } from '../../auth/states/branchSlice';

const SelectBranch = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const branchList = user?.branches;
  const branch_id = useAppSelector((state) => state.branchSlice.branch);
  const changeBranch = (e: string) => {
    dispatch(setBranch(e));
    //   dispatch(api.util.resetApiState());
    window.location.reload();
  };
  return (
    <Select
      style={{ width: 250, marginRight: 10 }}
      value={branch_id}
      onChange={changeBranch}
      options={[
        { label: 'All', value: 'all' },
        ...(branchList
          ? branchList.map((item) => ({ label: item.org_name, value: item.org_id }))
          : []),
      ]}
    />
  );
};

export default SelectBranch;
