import dayjs from 'dayjs';

export function excelDateRangeFormat(
  dateString: string = `from_date=${dayjs().format('YYYY-MM-DD')}&to_date=${dayjs().format(
    'YYYY-MM-DD'
  )}`
): string {
  return dateString.replace(
    /from_date=(\d{4})-(\d{2})-(\d{2})&to_date=(\d{4})-(\d{2})-(\d{2})/,
    'from_date=$3-$2-$1_to_date=$6-$5-$4'
  );
}
