import { Col, DatePicker, Divider, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectCommon, setModal } from '../../../common/slices/commonSlice';
import { HTTPResponse } from '../../../common/types/commonTypes';
import FileUpload from '../../../common/utils/FileUpload';
import {
  DateInput,
  DateInputMonth,
  FormButton,
  FormInput,
  FormInputItem,
  FormInputTransaction,
  NumberInput,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';
import { SelectEmployee } from '../../../components/common/FormItem/SelectCustomFeilds';
import SelectPaymentMethod from '../../../components/common/paymentMethod/SelectPaymentMethod';
import { useGetAllAccountsQuery } from '../../Accounts/Api/Endpoints/accountsEndpoints';
import {
  useLazyEmployeeSalaryByIdQuery,
  useLazyViewEmployeeCommissionQuery,
} from '../../Configuration/employee/endpoints/employeeEndpoints';
import { SeperateValueObject } from '../../Invoice_Air_Ticket/types/invoiceAirTicketTypes';
import { useGetAccountByTypeQuery } from '../../vendor/api/endpoints/vendorPaymentEndpoints';
import AccountLastBalanceInput from '../../vendor/components/AccountLastBalanceInput';
import {
  useEditEmployeeExpenseMutation,
  useLazyGetEmployeeAdvancePayrollQuery,
  usePostEmployeeExpenseMutation,
} from '../api/endpoints/payrollEndpoints';
import { IPayrollCreate, IPayrollGetForEdit } from '../types/PayrollTypes';
import Deductions from './Deductions';
import Others from './Others';
type Props = {
  editOrAdd: {
    reason: 'ADD' | 'EDIT';
    id?: number | undefined;
  };
  getForEditData: HTTPResponse<IPayrollGetForEdit> | undefined;
};

export default function AddPayrollContent({ editOrAdd, getForEditData }: Props) {
  const [form] = Form.useForm();
  const navigation = useNavigate();
  const employeeId = useWatch(['payroll_employee_id'], form);
  const month_value = useWatch(['payment_month'], form);

  const { data: accountsData } = useGetAllAccountsQuery();
  const [fetchEmployeeSalary, { data }] = useLazyEmployeeSalaryByIdQuery();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>(1);
  const { data: accounts } = useGetAccountByTypeQuery(Number(payMethodCatId));

  const [fetchEmployeeCommission, { data: employeeCommission }] =
    useLazyViewEmployeeCommissionQuery();

  useEffect(() => {
    if (employeeId && month_value) {
      fetchEmployeeCommission({
        employee_id: employeeId,
        month: dayjs(month_value).format('YYYY-MM'),
      });
    }
  }, [employeeId, month_value]);

  useEffect(() => {
    if (employeeCommission?.data?.net_total || employeeCommission?.data?.net_total == 0) {
      form.setFieldValue('payroll_commission', Number(employeeCommission?.data?.net_total));
    }
  }, [employeeCommission?.data?.net_total, employeeId, month_value]);

  useEffect(() => {
    form.resetFields(['payroll_account_id']);
    form.resetFields(['availavleBalance']);
  }, [accounts]);
  // POST QUERY
  const [
    postEmployeeExpense,
    {
      isLoading: postIsLoading,
      isSuccess: postIsSuccess,
      isError: postIsError,
      isLoading: postLoading,
    },
  ] = usePostEmployeeExpenseMutation();

  // ================== notification =========================

  useEffect(() => {
    if (postIsSuccess) {
      message.success('Payroll has been added!');
      navigation('/payroll');

      dispatch(setModal(false));
    }
    if (postIsError) {
      message.error('Payroll  can not added!');
    }
  }, [postIsLoading]);

  // EDIT QUERY
  const [
    editEmployeeExpense,
    {
      isLoading: editIsLoading,
      isSuccess: editIsSuccess,
      isError: editIsError,
      isLoading: editEmployeeLoading,
    },
  ] = useEditEmployeeExpenseMutation();
  useEffect(() => {
    if (editIsSuccess) {
      message.success('Payroll has been Updated!');
      navigation(`/payroll/${editOrAdd.id}`);
      dispatch(setModal(false));
    }
  }, [editIsSuccess]);
  useEffect(() => {
    if (editIsError) {
      message.error('Update faild!');
    }
  }, [editIsError]);

  const accountOptions: SeperateValueObject[] | undefined = accountsData?.data?.map((item) => ({
    id: item.account_id,
    title: item.account_name,
    mobileNo: item.account_number,
    costPrice: Number(item?.amount || 0),
  }));

  const payroll_account_id = useWatch(['payroll_account_id'], form);

  useEffect(() => {
    if (payroll_account_id && accountOptions?.length) {
      const find = accountOptions.find((item) => item.id === payroll_account_id);
      form.setFieldValue(['availavleBalance'], Number(find?.costPrice));
    }
  }, [payroll_account_id]);

  useEffect(() => {
    form.setFieldsValue({
      payroll_date: dayjs(),
    });
  }, []);

  // HANDLE SUBMIT
  const handleSubmit = (values: IPayrollCreate) => {
    const { payroll_image_url, othersList, payroll_deductions, ...others } = values;
    delete values.availavleBalance;

    const formData = new FormData();

    for (const key in others) {
      const value = values[key as keyof IPayrollCreate];

      if (value !== undefined && value !== null) {
        if (
          key === 'payroll_salary_month' ||
          key === 'cheque_withdraw_date' ||
          key === 'payroll_date'
        ) {
          formData.append(key, dayjs(value).format('YYYY-MM-DD'));
        } else {
          formData.append(key, value.toString());
        }
      }
    }
    if (othersList?.length) {
      othersList.forEach((ele, index) => {
        formData.append(`payroll_other${index + 1}`, ele.payroll_other);
      });
    }
    if (values.payroll_image_url) {
      formData.append('payroll_image_url', values?.payroll_image_url[0]?.originFileObj);
    }

    if (payroll_deductions?.length) {
      formData.append('payroll_deductions', JSON.stringify(payroll_deductions));
    }
    if (editOrAdd.reason === 'ADD') {
      formData.append('payroll_created_by', String(user?.user_id));
      postEmployeeExpense(formData);
    } else if (editOrAdd.reason === 'EDIT') {
      formData.append('payroll_updated_by', String(user?.user_id));
      editEmployeeExpense({ body: formData, id: Number(editOrAdd.id) });
    }
  };

  const payroll_salary = useWatch(['payroll_salary'], form);
  const payroll_deductions = useWatch(['payroll_deductions'], form);
  const payroll_others = useWatch(['othersList'], form);
  const payroll_mobile_bill = useWatch(['payroll_mobile_bill'], form);
  const payroll_food_bill = useWatch(['payroll_food_bill'], form);
  const payroll_bonus = useWatch(['payroll_bonus'], form);
  const payroll_fastival_bonus = useWatch(['payroll_fastival_bonus'], form);
  const payroll_ta = useWatch(['payroll_ta'], form);
  const payroll_advance = useWatch(['payroll_advance'], form);
  const availavle_Balance = useWatch(['availavleBalance'], form);

  const payroll_provident = useWatch(['payroll_provident'], form);
  const payroll_health = useWatch(['payroll_health'], form);
  const payroll_incentive = useWatch(['payroll_incentive'], form);
  const payroll_accommodation = useWatch(['payroll_accommodation'], form);
  const payroll_commission = useWatch(['payroll_commission'], form);
  const payroll_attendance = useWatch(['payroll_attendance'], form);
  const payroll_profit_share = useWatch(['payroll_profit_share'], form);
  const payroll_salary_month = useWatch(['payroll_salary_month'], form);
  const payroll_employee_id = useWatch(['payroll_employee_id'], form);

  const [getAdvance, { data: employeeAdvance, isSuccess: employeeAdvSucc }] =
    useLazyGetEmployeeAdvancePayrollQuery();

  useEffect(() => {
    if (payroll_salary_month && payroll_employee_id) {
      getAdvance({
        employee_id: payroll_employee_id,
        month: payroll_salary_month,
      });
    }
  }, [payroll_salary_month, payroll_employee_id]);

  useEffect(() => {
    if (employeeAdvance && employeeAdvance) {
      form.setFieldsValue({
        payroll_advance: employeeAdvance.data?.total_advance,
      });
    }
  }, [employeeAdvSucc, employeeAdvance]);

  const daily_salary = (Number(payroll_salary) / 30).toFixed(2) || 0;

  // DAILY SALARY
  useEffect(() => {
    form.setFieldValue('daily_salary', daily_salary);
    form.setFieldValue('payroll_attendance', 30);
  }, [payroll_salary]);

  // GROSS SALARY
  const gross_salary = Number(daily_salary) * payroll_attendance || 0;
  useEffect(() => {
    form.setFieldValue('gross_salary', Math.round(gross_salary));
  }, [payroll_attendance, employeeId, daily_salary]);

  const isChanged = useRef(false);

  useEffect(() => {
    if (isChanged.current) {
      form.setFieldsValue({ payroll_account_id: undefined });
      return;
    }
  }, []);
  let totalDeduction = 0;
  payroll_deductions?.map((item: any) => {
    if (item && item.pd_amount) {
      totalDeduction += Number(item.pd_amount);
    }
  });

  let totalOtherList = 0;
  payroll_others?.map((item: any) => {
    if (item && item.payroll_other) {
      totalOtherList += Number(item.payroll_other);
    }
  });
  useEffect(() => {
    const netTotal =
      gross_salary -
      Number(totalDeduction || 0) +
      Number(payroll_mobile_bill || 0) +
      Number(totalOtherList || 0) +
      Number(payroll_food_bill || 0) +
      Number(payroll_bonus || 0) +
      Number(payroll_fastival_bonus || 0) +
      Number(payroll_ta || 0) -
      Number(payroll_advance || 0) -
      Number(payroll_provident || 0) +
      Number(payroll_health || 0) +
      Number(payroll_incentive || 0) +
      Number(payroll_accommodation || 0) +
      Number(payroll_profit_share || 0) +
      Number(payroll_commission || 0);

    form.setFieldValue(['payroll_net_amount'], Math.round(netTotal));
  }, [
    gross_salary,
    payroll_deductions,
    payroll_mobile_bill,
    payroll_food_bill,
    payroll_bonus,
    payroll_fastival_bonus,
    payroll_ta,
    payroll_advance,
    payroll_provident,
    payroll_health,
    payroll_incentive,
    payroll_accommodation,
    payroll_commission,
    payroll_profit_share,
    payroll_others,
  ]);

  useEffect(() => {
    form.validateFields(['payroll_net_amount']);
  }, [availavle_Balance]);

  // RESET FORM WHEN MODAL CLOSED
  const commonState = useSelector(selectCommon);
  useEffect(() => {
    if (!commonState.isModal) {
      form.resetFields();
      form.setFieldsValue({
        payroll_pay_type: 1,
        payroll_date: dayjs(),
      });
    }
  }, [commonState.isModal]);

  const layout = {
    labelCol: { span: 14 },
  };

  //SET CURRENT DATE
  useEffect(() => {
    form.setFieldValue('payroll_date', dayjs());
    form.setFieldValue('payroll_salary_month', dayjs());
  }, []);

  const validateNetTotal = () => {
    setTimeout(() => {
      form.validateFields(['payroll_net_amount']);
    }, 200);
  };

  //employee salary by Id
  useEffect(() => {
    if (editOrAdd.reason === 'ADD') {
      fetchEmployeeSalary(employeeId);
    }
  }, [employeeId]);

  if (editOrAdd.reason === 'ADD' && data?.data) {
    form.setFieldsValue({
      payroll_salary: data?.data?.employee_salary,
    });
  }

  //otherList setFieldsValue
  const payrollOtherListObject = {
    payroll_other1: getForEditData?.data?.payroll_other1,
    payroll_other2: getForEditData?.data?.payroll_other2,
    payroll_other3: getForEditData?.data?.payroll_other3,
  };
  const payrollArray = Object.entries(payrollOtherListObject)
    .map(([key, value]) => {
      if (value && key) {
        return {
          payroll_other: value,
        };
      }
    })
    .filter((item) => item !== undefined);

  // HANDLE EDIT AUTO FILL
  useEffect(() => {
    if (editOrAdd.reason === 'EDIT' && getForEditData) {
      setPayMethodCatId(getForEditData?.data?.payroll_pay_type);

      form.setFieldsValue({
        ...getForEditData?.data,
        othersList: payrollArray,
        payroll_account_id: getForEditData?.data?.payroll_account_id,
        payroll_date: dayjs(getForEditData?.data?.payroll_date),
        payroll_cheque_no: getForEditData?.data?.pcheque_number,
        payroll_bank_name: getForEditData?.data?.pcheque_bank_name,
        cheque_withdraw_date: dayjs(getForEditData?.data?.cheque_withdraw_date) || null,
        payroll_salary_month: dayjs(getForEditData?.data?.payroll_salary_month),
      });
    }
  }, [editOrAdd, getForEditData]);

  return (
    <Form {...layout} form={form} onFinish={handleSubmit} layout='vertical'>
      <Row gutter={[20, 0]}>
        <SelectEmployee
          label='Employee Name'
          required
          name={['payroll_employee_id']}
          offDropDown
          size={6}
        />

        <DateInput size={6} required month name={['payroll_salary_month']} label='Salary Month' />

        <NumberInput
          name={['payroll_salary']}
          required
          label='Base Salary'
          min='0'
          readOnly
          maxChar={14}
          minChar={0}
          onChange={validateNetTotal}
          size={6}
        />
        <NumberInput
          name={['daily_salary']}
          required
          label='Daily Salary'
          min='0'
          readOnly
          maxChar={14}
          minChar={0}
          onChange={validateNetTotal}
          size={6}
        />

        <NumberInput
          label='Attendance (Days)'
          name={['payroll_attendance']}
          max={31}
          min='0'
          size={6}
        />

        <NumberInput label='Gross Salary' name={['gross_salary']} min='0' size={6} />

        <SelectPaymentMethod
          mdSize={8}
          smSize={12}
          name='payroll_pay_type'
          form={form}
          label='Method'
          required
          onChange={(value: number) => {
            setPayMethodCatId(value);
            if (!isChanged.current) isChanged.current = true;
          }}
          cheque
          size={6}
        />

        {Number(payMethodCatId) !== 4 && (
          <AccountLastBalanceInput
            required
            accountsLastBalance={accounts ? accounts.data : []}
            name='payroll_account_id'
            label='Accounts'
            mdSize={8}
            smSize={12}
            size={6}
          />
        )}

        {Number(payMethodCatId) === 5 && (
          <>
            <FormInputTransaction
              chargeTypeName='card_trans_charge_type'
              name='card_trans_charge'
              label='Transaction charge '
              size={6}
            />
            <FormInputItem name='card_trans_no' label='Receipt/Trans No : ' size={6} />
          </>
        )}
        {Number(payMethodCatId) === 4 && (
          <>
            <FormInputItem label='Cheque No' name={['payroll_cheque_no']} required size={6} />

            <DateInput name='cheque_withdraw_date' label='Withdraw Date' required size={6} />

            <FormInputItem name={'payroll_bank_name'} label='Bank Name' required size={6} />
          </>
        )}

        {Number(payMethodCatId) === 3 && (
          <>
            <FormInputItem label='Receipt/Trans No:' name='payroll_transection_no' size={6} />

            <FormInputItem
              name={'payroll_transection_charge'}
              label='Transaction Charge :'
              size={6}
            />
          </>
        )}
        {Number(payMethodCatId) !== 4 && (
          <FormInput label='Available Balance' size={6} name={['availavleBalance']} readonly />
        )}
        <Divider>Deductions</Divider>
        <Col lg={24}>
          <Row>
            <Col lg={14}>
              {' '}
              <Form.List name='payroll_deductions' initialValue={[{}]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index, arr) => (
                      <Deductions
                        onChange={validateNetTotal}
                        name={name}
                        remove={remove}
                        add={add}
                        key={key}
                        form={form}
                        editOrAdd={editOrAdd.reason}
                        deductionLength={getForEditData?.data?.payroll_deductions.length}
                        index={index}
                      />
                    ))}
                  </>
                )}
              </Form.List>
            </Col>
            <Col lg={10}>
              <Row gutter={5}>
                <Col lg={12}>
                  <NumberInput
                    readOnly
                    name={['payroll_advance']}
                    label='Advance Salary'
                    size={24}
                    min='0'
                    maxChar={10}
                    minChar={0}
                    onChange={validateNetTotal}
                  />
                </Col>
                <Col lg={12}>
                  <NumberInput
                    name={['payroll_provident']}
                    label='Provident Fund'
                    size={24}
                    min='0'
                    maxChar={10}
                    minChar={0}
                    onChange={validateNetTotal}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Divider>Additional</Divider>

        <NumberInput
          name={['payroll_mobile_bill']}
          label='Mobile Bill'
          size={6}
          min='0'
          maxChar={10}
          minChar={0}
          onChange={validateNetTotal}
        />
        <NumberInput
          name={['payroll_food_bill']}
          label='Feed Allowance'
          size={6}
          min='0'
          maxChar={10}
          minChar={0}
          onChange={validateNetTotal}
        />
        <NumberInput
          name={['payroll_bonus']}
          label='Performance Bonus'
          size={6}
          min='0'
          maxChar={10}
          minChar={0}
          onChange={validateNetTotal}
        />
        <NumberInput
          name={['payroll_fastival_bonus']}
          label='Festival Bonus'
          size={6}
          min='0'
          maxChar={10}
          minChar={0}
          onChange={validateNetTotal}
        />
        <NumberInput
          name={['payroll_ta']}
          label='Travel Allowance'
          size={6}
          min='0'
          maxChar={10}
          minChar={0}
          onChange={validateNetTotal} //use this for realTime validation
        />

        <NumberInput
          name={['payroll_health']}
          label='Health Allowance'
          size={6}
          min='0'
          maxChar={10}
          minChar={0}
          onChange={validateNetTotal}
        />

        <NumberInput
          name={['payroll_incentive']}
          label='Incentive'
          size={6}
          min='0'
          maxChar={10}
          minChar={0}
          onChange={validateNetTotal}
        />

        <NumberInput
          name={['payroll_accommodation']}
          label='House Rent'
          size={6}
          min='0'
          maxChar={10}
          minChar={0}
          onChange={validateNetTotal}
        />

        <NumberInput
          name={['payroll_profit_share']}
          label='Profit Share'
          size={6}
          min='0'
          maxChar={10}
          minChar={0}
        />

        <DateInput
          month
          style={{ width: '100%' }}
          label='Select Month'
          name={'payment_month'}
          size={6}
        />

        <NumberInput
          name={['payroll_commission']}
          label='Sales Commission'
          min='0'
          size={6}
          maxChar={10}
          minChar={0}
          onChange={validateNetTotal}
        />
        <Col lg={24}>
          <Row>
            <Form.List name='othersList' initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, _, arr) => (
                    <Others
                      onChange={validateNetTotal}
                      name={name}
                      remove={remove}
                      add={add}
                      key={key}
                      arr={arr}
                    />
                  ))}
                </>
              )}
            </Form.List>
          </Row>
        </Col>

        <Divider>Net Total and Note</Divider>

        <DateInput size={6} required name={['payroll_date']} label='Salary Date' />

        <NumberInput
          name={['payroll_net_amount']}
          label='Total Salary'
          rules={[
            {
              validator: async (_, value) => {
                if (availavle_Balance && Number(value || 0) > Number(availavle_Balance || 0)) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
              message: 'Insufficient balance!',
            },
          ]}
          min='0'
          size={6}
          maxChar={10}
          minChar={0}
        ></NumberInput>

        <Col xs={24} sm={24} md={8} lg={6} xl={12} xxl={6}>
          <Form.Item
            label='Upload Docs'
            name={'payroll_image_url'}
            valuePropName='payroll_image_url'
          >
            <FileUpload
              size={24}
              smSize={24}
              mdSize={24}
              name='payroll_image_url'
              msg='Doc image'
              noBorder
              imagURL={getForEditData?.data?.payroll_image_url}
            />
          </Form.Item>
        </Col>

        <TextAreaInput name={['payroll_note']} label='Note' size={24} />

        <div style={{ width: '100%' }}>
          <FormButton
            label={editOrAdd.reason === 'ADD' ? 'Save Payroll' : 'Update Payroll'}
            loading={postLoading || editEmployeeLoading || postIsLoading}
          />
        </div>
      </Row>
    </Form>
  );
}
