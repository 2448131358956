import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { IPassportStatusReportDataTypes } from '../types/passportStatusReportTypes';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';

const passportStatusReportColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IPassportStatusReportDataTypes> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) + '/' + 'details' + '/' + invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (_, record) => (
        <Link
          to={
            record.passport_client_id
              ? `/clients/details/client-${record.passport_client_id}`
              : `/combineClients/details/${record.passport_combined_id}`
          }
        >
          {record.client_name}
        </Link>
      ),
    },
    {
      title: `Passport No`,
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
    },
    {
      title: 'Passport Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: 'Mobile',
      dataIndex: 'passport_mobile_no',
      key: 'passport_mobile_no',
    },
    {
      title: 'Gender',
      dataIndex: 'passport_person_type',
      key: 'passport_person_type',
    },

    {
      title: 'Country',
      dataIndex: 'country_name',
      key: 'country_name',
    },
    {
      title: 'Status',
      dataIndex: 'pstatus_name',
      key: 'pstatus_name',
    },
    {
      title: 'Note',
      dataIndex: 'passport_note',
      key: 'passport_note',
    },
  ];
};

export default passportStatusReportColumn;
