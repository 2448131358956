import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import { IPassportWiseReportDataType } from '../types/passportWiseReportTypes';

const passportWiseReportColumn = (): ColumnsType<IPassportWiseReportDataType> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => index + 1,
    },

    {
      title: 'Invoice',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) + '/' + 'details' + '/' + invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },

    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (_, record) => (
        <Link
          to={
            record.passport_client_id
              ? `/clients/details/client-${record.passport_client_id}`
              : `/combineClients/details/${record.passport_combined_id}`
          }
        >
          {record.client_name}
        </Link>
      ),
    },

    {
      title: 'Passport No',
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
    },
    {
      title: 'Passport Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: 'Mobile',
      dataIndex: 'passport_mobile_no',
      key: 'passport_mobile_no',
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
    },
    {
      title: 'Status',
      dataIndex: 'passport_status',
      key: 'passport_status',
    },
    {
      title: 'Note',
      dataIndex: 'passport_note',
      key: 'passport_note',
    },
  ];
};

export default passportWiseReportColumn;
