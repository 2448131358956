import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Col, ConfigProvider, DatePicker, Form, Row, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  TableParams,
  handleTableChange,
  rangePresets,
} from "../../../../common/utils/common.utils";
import { FormButton } from "../../../../components/common/FormItem/FormItems";
import { SelectEmployee } from "../../../../components/common/FormItem/SelectCustomFeilds";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import { useLazyGetExcelQuery } from "../../ledgers/endpoints/ledgerEndpoints";
import { useLazyGetAllEmployeExpenseReportQuery } from "../endpoints/EmployeeExpenseReportEndPoint";
import { IEmployeeExpenseReportFormDatatype } from "../types/EmployeeExpenseReportTypes";
import { EmployeeExpenseReportColumn } from "../utils/EmployeeExpenseReportColumn";
import { excelDateRangeFormat } from "../../../../common/utils/excelDateRangeFormat";

const EmployeeExpenseReport = () => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: "",
    query: "",
  });
  let totalAmount: number = 0;
  const [fetchEmployeExpenseReport, { data, isLoading }] = useLazyGetAllEmployeExpenseReportQuery();

  if (data?.data) {
    for (let i = 0; i < data?.data?.length; i++) {
      totalAmount += Number(data.data[i].payroll_net_amount);
    }
  }

  const onFinish = async (values: IEmployeeExpenseReportFormDatatype) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `?from_date=${setFrom_date}&to_date=${setTo_date}`;
    setDate_range_value(date_range);
    const body: IEmployeeExpenseReportFormDatatype = {
      employee_id: values.employee_id,
      date_range: date_range,
    };
    await fetchEmployeExpenseReport({
      employee_id: body.employee_id,
      query: `${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: body.employee_id.toString(),
      query: `${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };
  const to_date = dayjs().format("YYYY-MM-DD");
  useEffect(() => {
    const date_range = `?from_date=${to_date}&to_date=${to_date}`;
    form.setFieldValue("date_range", [dayjs(), dayjs()]);
    form.setFieldValue("employee_id", "all");
    const body = {
      employee_id: "all",
      query: `${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    };
    fetchEmployeExpenseReport({
      employee_id: body.employee_id,
      query: body.query,
    });
    setExcelQuery({
      Id: "all",
      query: `${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);

  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: "0" | "1";
    current: number;
    pageSize: number;
  }>({ current: 1, isTrash: "0", pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ["20", "50", "100", "200", "500"],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IEmployeeExpenseReportFormDatatype = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    fetchEmployeExpenseReport({
      employee_id: values.employee_id,
      query: `${query}&${date_range}`,
    });
    setExcelQuery({
      Id: values.employee_id.toString(),
      query: `${query}&${date_range}`,
    });
  };
  //pagination------end--------------
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Employee_Expense_Report",
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <ConfigProvider>
      {data && (
        <Table
          size="small"
          bordered
          columns={EmployeeExpenseReportColumn(queryData)}
          dataSource={data.data}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
          pagination={count > 20 ? tableParams.pagination : false}
          onChange={(args1, args2, args3) =>
            handleTableChange({
              args: {
                pagination: args1,
                filters: args2,
                sorter: args3,
              },
              states: {
                refetch,
                setQueryData,
                setTableParams,
                tableParams,
              },
            })
          }
          summary={(_) => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Typography.Text strong></Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="right">
                    <div
                      style={{
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      Total:{" "}
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <div
                      style={{
                        color: "green",
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>{totalAmount}</Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      )}
    </ConfigProvider>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "Employee expense information" }}
        extraInfo={{
          date:
            date_range_value
              ?.split("&")
              ?.map((param) => dayjs(param.split("=")[1])?.format("DD-MM-YYYY"))
              ?.join(" to ") || "",
        }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={["Reports", "Salaries Expense Report"]} />

      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type="primary"
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: "expense/employeeExpense",
              excelName: `employee_expense_report_${excelDateRangeFormat(
                date_range_value as string
              )}`,
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>

      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <SelectEmployee
            required
            name="employee_id"
            size={5}
            label="Select Employee"
            placeholder="Select Employee"
            showAll
            offDropDown
          />
          <Col span={6} xs={24} sm={24} md={24} lg={4}>
            <Form.Item
              label="Date Range"
              name="date_range"
              rules={[{ required: true, message: ` Date Range is required!` }]}
            >
              <DatePicker.RangePicker
                presets={rangePresets}
                format={"YYYY-MM-DD"}
                allowClear={false}
              />
            </Form.Item>
          </Col>
          <Col lg={2}>
            <FormButton label="Search" icon textAlign="left" />
          </Col>
        </Row>
      </Form>
      <div ref={componentRef}>
        <FormHeaderTitle title="Salaries expense information" />

        {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
        {jsx_com}
      </div>

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default EmployeeExpenseReport;
