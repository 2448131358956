import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons/lib/icons';
import { Button, Card, Col, ConfigProvider, Descriptions, Form, Row } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Fixed2 } from '../../../../common/utils/common.utils';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useLazyGetExcelOverAllProfitLossQuery,
  useLazyGetOverAllProfitlossQuery,
} from '../endpoints/profitLossEndpoints';
import { IProfitLossFormData } from '../types/overAllprofitLossTypes';
import { excelDateRangeFormat } from '../../../../common/utils/excelDateRangeFormat';

const Over_All_Profit_Loss = () => {
  const [paramData, setParamData] = useState<{
    date_range: string;
    id: number | string;
  }>();
  const location = useLocation();

  const [form] = Form.useForm();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [fetchExcel] = useLazyGetExcelOverAllProfitLossQuery();
  const [excelQuery, setExcelQuery] = useState<{
    query: string;
  }>({
    query: '',
  });

  const [fetchOverAllProfitLoss, { data, isLoading, isFetching }] =
    useLazyGetOverAllProfitlossQuery();

  const toDayDate = dayjs().format('YYYY-MM-DD');
  // console.log(overAllProfit);

  const incomeData = data?.data?.filter((item) => item.trxn_type === 'INCOME');
  const salesData = incomeData?.find((item) => item.category === 'SALES');
  const incomeDataWithoutSale = incomeData?.filter((item) => item.category !== 'SALES');
  const totalIncome = incomeData?.reduce((sum, item) => sum + (item.total_amount || 0), 0);
  const expenseData = data?.data?.filter((item) => item.trxn_type === 'EXPENSE');
  const purchaseData = expenseData?.find((item) => item.category === 'PURCHASE');
  const expenseDataWithoutPurchase = expenseData?.filter((item) => item.category !== 'PURCHASE');
  const totalExpense = expenseData?.reduce((sum, item) => sum + (item.total_amount || 0), 0);
  // net profit or loss
  const netProfitOrLoss = (totalIncome || 0) - (totalExpense || 0);
  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    const date_range = `from_date=${toDayDate}&to_date=${toDayDate}`;
    const body: any = {
      date_range: date_range,
    };
    fetchOverAllProfitLoss(body);
    setExcelQuery({
      query: date_range,
    });

    setParamData({
      date_range: date_range,
      id: '',
    });
  }, []);

  // This useEffect is working based on Params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dailyValue = searchParams.get('daily');

    const monthFormDate = searchParams.get('from_date');
    const monthToDate = searchParams.get('to_date');

    const yearFormDate = searchParams.get('y_from_date');
    const yearToDate = searchParams.get('y_to_date');

    if (dailyValue) {
      onFinish({
        date_range: [dailyValue, dailyValue],
      });
    }

    if (monthFormDate && monthToDate) {
      form.setFieldValue('date_range', [dayjs(monthFormDate), dayjs(monthToDate)]);
      onFinish({
        date_range: [monthFormDate, monthToDate],
      });
    }

    if (yearFormDate && yearToDate) {
      form.setFieldValue('date_range', [dayjs(yearFormDate), dayjs(yearToDate)]);
      onFinish({
        date_range: [yearFormDate, yearToDate],
      });
    }
  }, [location.search]);

  const onFinish = async (values: IProfitLossFormData) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;

    const body: any = {
      date_range: date_range,
    };
    setExcelQuery({
      query: date_range,
    });
    setDate_range_value(date_range);
    setParamData({
      date_range: date_range,
      id: '',
    });
    await fetchOverAllProfitLoss(body);
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Profit_Loss_${date_range_value}`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <Row gutter={[12, 12]}>
      <Col xs={24} sm={24} md={24} lg={12}>
        <ConfigProvider>
          <Card>
            <FormHeaderTitle title='Sales Income' />
            <Descriptions size='small' column={1} bordered>
              <DescriptionsItem
                label={
                  // <Link
                  //   to={`../reports/daily_sales_report?${paramData?.date_range}`}
                  // >
                  //   {salesData?.category}
                  // </Link>
                  salesData?.category
                }
              >
                {salesData?.total_amount}
              </DescriptionsItem>
              <DescriptionsItem
                label={
                  // <Link
                  //   to={`../reports/vendor_wise_purchase_and_payment?${paramData?.date_range}`}
                  // >
                  //   {purchaseData?.category}
                  // </Link>
                  purchaseData?.category
                }
              >
                {purchaseData?.total_amount}
              </DescriptionsItem>
              <DescriptionsItem
                label={
                  // <Link to={"../reports/monthly_sales_and_earning"}>
                  'SALES PROFIT/LOSS'
                  // </Link>
                }
              >
                {Fixed2(salesData?.total_amount || 0) - Fixed2(purchaseData?.total_amount || 0)}
              </DescriptionsItem>
            </Descriptions>
          </Card>
        </ConfigProvider>

        {/* Income */}
        <ConfigProvider>
          <Card style={{ marginTop: '1rem' }}>
            <FormHeaderTitle title='Income' />
            <Descriptions size='small' column={1} bordered>
              {incomeDataWithoutSale?.map((item, index) => (
                <DescriptionsItem key={index} label={item.category}>
                  {item?.total_amount || 0}
                </DescriptionsItem>
              ))}
            </Descriptions>
          </Card>
        </ConfigProvider>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        {/* Expense */}
        <ConfigProvider>
          <Card style={{ marginTop: '1rem' }}>
            <FormHeaderTitle title='Expense' />
            <Descriptions size='small' column={1} bordered>
              {expenseDataWithoutPurchase?.map((item, index) => (
                <DescriptionsItem key={index} label={item.category}>
                  {item?.total_amount || 0}
                </DescriptionsItem>
              ))}
            </Descriptions>
          </Card>
        </ConfigProvider>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        {/* Net Profit/Loss */}
        <ConfigProvider>
          <Card style={{ marginTop: '1rem' }}>
            <FormHeaderTitle title='Net Profit/Loss' />
            <Descriptions
              size='small'
              bordered
              column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <DescriptionsItem label='Total Income'>{totalIncome || 0}</DescriptionsItem>
              <DescriptionsItem label='Total Expense'>{totalExpense || 0}</DescriptionsItem>
              <DescriptionsItem
                style={{
                  fontWeight: 'bold',
                  color: netProfitOrLoss && netProfitOrLoss > 0 ? 'green' : 'red',
                }}
                label='Net Profit/Loss'
              >
                {Math.abs(netProfitOrLoss).toFixed(2)}
              </DescriptionsItem>
            </Descriptions>
          </Card>
          {(isLoading || isFetching) && <LoadingIndicator />}
        </ConfigProvider>
      </Col>
    </Row>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'OverAll Profit Loss' }}
        extraInfo={{
          date:
            date_range_value
              ?.split('&')
              ?.map((param) => dayjs(param.split('=')[1])?.format('DD-MM-YYYY'))
              ?.join(' to ') || '',
        }}
      />
    </div>
  );
  return (
    <div>
      <BreadCrumb arrOfOption={['Report', 'ProfitLoss', 'OverAll Profit Loss']} />

      <Row justify={'space-between'}>
        <Col lg={18}>
          <Form layout='vertical' form={form} onFinish={onFinish}>
            <ReportsHeader size={8} required date_range_value={date_range_value} date_range />
          </Form>
        </Col>

        <Col lg={6}>
          <Button type='primary' style={{ margin: 15 }} onClick={handlePrint}>
            <PrinterOutlined />
            Print
          </Button>
          <Button
            type='primary'
            onClick={() => {
              fetchExcel({
                query: excelQuery.query,
                excelName: `over_all_profit_loss_${excelDateRangeFormat(
                  date_range_value as string
                )}`,
              });
            }}
          >
            <FileTextOutlined />
            Excel Report
          </Button>
        </Col>
      </Row>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </div>
  );
};

export default Over_All_Profit_Loss;
