import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Form, Space, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { TableParams, handleTableChange } from '../../../../common/utils/common.utils';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import { useLazyGetAllTransactionChargeReportQuery } from '../endpoints/onlineTransactionChargeEndpoints';
import {
  IOnlineChargeReportDataTypes,
  IOnlineChargeReportFormDataTypes,
} from '../types/onlineTransactionCharge';
import { OnlineChargeUtils } from '../utils/OnlineChargeUtils';
import { useReactToPrint } from 'react-to-print';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import { excelDateRangeFormat } from '../../../../common/utils/excelDateRangeFormat';

const OnlineTransactionChargeReport = () => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [excelQuery, setExcelQuery] = useState<{
    query: string;
  }>({
    query: '',
  });
  let totalAmount = 0;
  const [fetchOnlineCharge, { data, isLoading }] = useLazyGetAllTransactionChargeReportQuery({
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const count = cache?.data?.count;
      const dataToReturn: IOnlineChargeReportDataTypes[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          totalAmount += Number(element.charge_amount);
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: { dataToReturn, count } };
    },
  });

  const onFinish = async (values: IOnlineChargeReportFormDataTypes) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    setDate_range_value(date_range);
    const body: IOnlineChargeReportFormDataTypes = {
      date_range: date_range,
    };
    fetchOnlineCharge({
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  const toDate = dayjs().format('YYYY-MM-DD');
  useEffect(() => {
    const date_range = `from_date=${toDate}&to_date=${toDate}`;
    form.setFieldValue('date_range', [dayjs(), dayjs()]);

    fetchOnlineCharge(
      {
        query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
      },
      true
    );
    setExcelQuery({
      query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);
  //pagination--start---
  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100', '200', '500'],
      pageSize: 20,
      total: data.count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: data.count },
    }));
  }, [data.count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IOnlineChargeReportFormDataTypes = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body: IOnlineChargeReportFormDataTypes = {
      date_range: date_range,
    };

    fetchOnlineCharge({
      query: `${query}&${body.date_range}`,
    });
    setExcelQuery({
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <Table
      size='small'
      bordered
      columns={OnlineChargeUtils(queryData)}
      dataSource={data.dataToReturn}
      scroll={{ x: true }}
      pagination={data.count !== undefined && data.count > 20 ? tableParams.pagination : false}
      onChange={(args1, args2, args3) =>
        handleTableChange({
          args: {
            pagination: args1,
            filters: args2,
            sorter: args3,
          },
          states: {
            refetch,
            setQueryData,
            setTableParams,
            tableParams,
          },
        })
      }
      summary={(_) => {
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={0} colSpan={5}>
                <div
                  style={{
                    fontWeight: '700',
                    textAlign: 'right',
                  }}
                >
                  <Typography.Text strong>Total: </Typography.Text>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <div
                  style={{
                    fontWeight: '700',
                    textAlign: 'right',
                  }}
                >
                  <Typography.Text strong>{Number(totalAmount)}</Typography.Text>
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Online Transaction Charge Report' }}
        extraInfo={{
          date:
            date_range_value
              ?.split('&')
              ?.map((param) => dayjs(param.split('=')[1])?.format('DD-MM-YYYY'))
              ?.join(' to ') || '',
        }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Transaction Charge Report']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: '',
              query: excelQuery.query,
              excelApiName: 'onlineTrxnChargeReport',
              excelName: `Online_Trxn_Charge_Report_${excelDateRangeFormat(
                date_range_value as string
              )}`,
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader date_range size={6} reports_title='Online Transaction Charge Report' />
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}

      {isLoading && <LoadingIndicator />}
    </>
  );
};

export default OnlineTransactionChargeReport;
