import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Form, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Fixed2 } from "../../../../common/utils/common.utils";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import ReportsHeader from "../../../../components/common/ReportsHeader";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import { useLazyGetExcelQuery } from "../../ledgers/endpoints/ledgerEndpoints";
import { useGetTicketWiseProfitQuery } from "../endpoints/profitLossEndpoints";
import { ITicketWiseProfitLossFormDataType } from "../types/ticketWiseProfitLossTypes";
import { ticketWiseProfitColumn } from "../utils/ticketWiseProfitColumn";
import { useLocation } from "react-router";
import { excelDateRangeFormat } from "../../../../common/utils/excelDateRangeFormat";

const Ticket_Wise_Profit_loss = () => {
  const [paramData, setParamData] = useState<{
    date_range: string;
    id: number | string;
  }>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [form] = Form.useForm();
  const [fetchExcel, { isLoading: excelLoading }] = useLazyGetExcelQuery();
  const to_date = dayjs().format("YYYY-MM-DD");

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: "",
    query: "",
  });
  let airticket_profit: number = 0;

  const [dateRange, setDateRange] = useState(`from_date=${to_date}&to_date=${to_date}`);
  const [ticketId, setTicketId] = useState("all");

  const { data, isLoading, isFetching } = useGetTicketWiseProfitQuery({
    ...pagination,
    date_range: dateRange,
    ticket_id: ticketId,
  });

  if (data?.data) {
    for (let i = 0; i < data.data.length; i++) {
      const element = data?.data[i];

      airticket_profit += Number(element.airticket_profit);
    }
  }
  const onFinish = async (values: ITicketWiseProfitLossFormDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;

    setDateRange(date_range);
    setTicketId(values.ticket_id);

    setExcelQuery({
      Id: values.ticket_id.toString(),
      query: `?${date_range}`,
    });
  };

  useEffect(() => {
    if (searchParams && location.search) {
      const formDate = searchParams.get("from_date");
      const toDate = searchParams.get("to_date");
      const id = searchParams.get("airticket_id");
      form.setFieldValue("date_range", [dayjs(formDate), dayjs(toDate)]);
      form.setFieldValue("ticket_id", Number(id));
    } else {
      form.setFieldValue("date_range", [dayjs(), dayjs()]);

      form.setFieldValue("ticket_id", "all");

      setExcelQuery({
        Id: "all",
        query: `?from_date=${to_date}&to_date=${to_date}`,
      });
    }

    form.submit();
  }, []);
  useEffect(() => {
    setParamData({
      date_range: `${dateRange}`,
      id: "",
    });
  }, [dateRange]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Monthly_Report`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <ConfigProvider>
        <Table
          size="small"
          bordered
          columns={ticketWiseProfitColumn({ pagination, paramData })}
          dataSource={data?.data}
          loading={{
            spinning: isLoading || isFetching || excelLoading,
            indicator: loadingIndicator,
          }}
          pagination={
            data?.count !== undefined && data?.count < 20
              ? false
              : {
                  ...pagination,
                  total: data?.count,
                  showSizeChanger: true,
                  pageSizeOptions: ["20", "50", "100", "200", "500"],
                  onChange: handlePaginationChange,
                }
          }
          summary={(_) => {
            let salesTotal = 0;
            let purchaseTotal = 0;
            let profitLossTotal = 0;

            _.forEach(({ airticket_client_price, airticket_purchase_price, airticket_profit }) => {
              salesTotal += Number(airticket_client_price);
              purchaseTotal += Number(airticket_purchase_price);
              profitLossTotal += Number(airticket_profit);
            });

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={8}>
                    {" "}
                    <Typography
                      style={{
                        textAlign: "right",
                        marginRight: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Total:
                    </Typography>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={9} align="right">
                    <Typography
                      style={{
                        textAlign: "right",
                        marginRight: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      {Fixed2(salesTotal || 0)}
                    </Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align="right">
                    <Typography
                      style={{
                        textAlign: "right",
                        marginRight: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      {Fixed2(purchaseTotal || 0)}
                    </Typography>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={0}>
                    <Typography
                      style={{
                        textAlign: "right",
                        marginRight: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      {Fixed2(profitLossTotal || 0)}
                    </Typography>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} />
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </ConfigProvider>
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "Ticket Wise Profit Loss Report" }}
        extraInfo={{
          date:
            paramData?.date_range
              ?.split("&")
              ?.map((param) => dayjs(param.split("=")[1])?.format("DD-MM-YYYY"))
              ?.join(" to ") || "",
        }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={["Reports", "ProfitLoss", "Ticket Wise Profit Loss Report"]} />
      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type="primary"
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: "profitLoss/ticketWiseProfit",
              excelName: `ticket_wise_${excelDateRangeFormat(dateRange as string)}`,
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <ReportsHeader
          required
          reports_title="Ticket Wise Profit Loss Report"
          showSelectTicket
          date_range
        />
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default Ticket_Wise_Profit_loss;
