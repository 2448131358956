import { api } from '../../../../app/baseQuery';
import { IBalanceStatus } from '../../AccountsTypes/AccountsTypes';
import {
  ACCOUNT_LIST,
  BALANCE_STATUS,
  BALANCE_TRANSFER,
  INCENTIVE_INCOME,
  INVESTMENTS,
  NON_INVOICE_INCOME,
  OPENING_BALANCE,
} from '../Constants/constants';
interface HTTPResponse<T> {
  success: boolean;
  count?: {
    accTypeOneCount: {
      row_count: number;
    };
    accTypeTwoCount: {
      row_count: number;
    };
    accTypeThreeCount: {
      row_count: number;
    };
  };
  total?: number;
  data?: T;
  message?: string;
}
export const balanceStatusEndpounts = api.injectEndpoints({
  endpoints: (build) => ({
    //get all balance status
    getAllBalanceStatus: build.query<HTTPResponse<IBalanceStatus>, void>({
      query: () => ({ url: `/accounts/balance-status` }),
      providesTags: () => [
        { type: 'Balance_Status', id: BALANCE_STATUS },
        { type: 'Opening_Balance', id: OPENING_BALANCE },
        { type: 'Accounts', id: ACCOUNT_LIST },
        { type: 'Balance_Transfer', id: BALANCE_TRANSFER },
        { type: 'Investments', id: INVESTMENTS },
        { type: 'Non_Invoice_Income', id: NON_INVOICE_INCOME },
        { type: 'Incentive_Income', id: INCENTIVE_INCOME },
      ],
    }),
  }),
});

export const { useGetAllBalanceStatusQuery } = balanceStatusEndpounts;
