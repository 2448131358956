import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Space, Table, Typography, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import {
  Fixed2,
  TableParams,
  handleTableChange,
  pagination,
  rangePresets,
} from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { SelectClientsOnly } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useLazyGetClientLedgerbyIDQuery,
  useLazyGetExcelQuery,
} from '../endpoints/ledgerEndpoints';
import { IClientLedgerFormData } from '../types/clientLedgerTypes';
import { ClientLedgerUtils } from '../utils/ClientLedgerUtils';
import { excelDateRangeFormat } from '../../../../common/utils/excelDateRangeFormat';

type Props = {};

export const landscapePageStyle = `
    @page {
        size: A4 landscape;
    }
    @media print {
        body {
            margin: 0;
            padding: 0;
        }
       
    }
`;
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function ClientLedgers({}: Props) {
  const query = useQuery();
  const queryId = query.get('client_id');

  useEffect(() => {
    if (queryId) {
      form.setFieldValue('client_id', Number(queryId));
      form.setFieldValue('date_range', [dayjs().subtract(1, 'month'), dayjs()]);
      form.submit();
    } else {
      form.setFieldValue('date_range', [dayjs(), dayjs()]);
    }
  }, [queryId]);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [date_range_value, setDate_range_value] = useState<string>();
  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 20 });

  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });
  const [fetchExcel] = useLazyGetExcelQuery();
  const [
    fetchClientLedger,
    {
      data: clientLedger,
      isLoading: clientFetchLoading,
      isFetching: clientLoading,
      isSuccess: clientFetchSuccess,
    },
  ] = useLazyGetClientLedgerbyIDQuery();
  const clientDetails = clientLedger?.data?.client;

  const clientInfoData = [
    {
      key: '1',
      name: 'Name',
      value: clientDetails?.client_name,
    },
    {
      key: '2',
      name: 'Mobile',
      value: clientDetails?.client_mobile,
    },
    {
      key: '3',
      name: 'Email',
      value: clientDetails?.client_email,
    },
    {
      key: '4',
      name: 'Address',
      value: clientDetails?.client_address,
    },
  ];

  const clientColumn = [
    {
      dataIndex: 'name',
      key: 'name',
      width: '150px',
    },
    {
      dataIndex: 'value',
      key: 'value',
      width: '120px',
    },
  ];

  const clientLedgerOnFinish = async (values: IClientLedgerFormData) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    setDate_range_value(date_range);
    const body: IClientLedgerFormData = {
      client_id: id ? Number(id.toString().split('-')[1]) : (values.client_id as number),
      date_range: date_range,
    };
    fetchClientLedger({
      client_id: body.client_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: body.client_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  useEffect(() => {
    if (id) {
      form.setFieldValue('client_id', Number(id.toString().split('-')[1]));
      form.setFieldValue('date_range', [dayjs().subtract(1, 'months'), dayjs()]);
      message.info('Showing last month record');
      form.submit();
    }
  }, [id]);
  const componentRef = useRef(null);

  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(clientLedger?.count || 0);
  }, [clientLedger]);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IClientLedgerFormData = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body: IClientLedgerFormData = {
      client_id: id ? Number(id.toString().split('-')[1]) : (values.client_id as number),
      date_range: date_range,
    };

    fetchClientLedger({
      client_id: body.client_id,
      query: `${query}&${body.date_range}`,
    });
    setExcelQuery({
      Id: body.client_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---

  const LastPage = Math.ceil(
    Number(clientLedger?.count || 0) / (tableParams?.pagination?.pageSize || 0)
  );

  // @LEDGER CONTENTS
  const ledger_content = clientLedger?.data ? (
    <div className='p-5 my-20 '>
      <Typography.Text style={{ margin: 10 }}>You have total: {count} rows</Typography.Text>
      <Table
        showHeader
        size='small'
        bordered
        rowKey={(e) => e.ctrxn_id}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        pagination={count > 20 ? tableParams.pagination : false}
        dataSource={clientLedger?.data?.ledgers}
        columns={ClientLedgerUtils(queryData)}
        loading={{
          spinning: clientFetchLoading || clientLoading,
          indicator: loadingIndicator,
        }}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
        summary={(_) => {
          if (tableParams.pagination?.current === LastPage)
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={8}>
                  <div className=''>
                    <Typography.Text strong>Total: </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1} colSpan={2}>
                  <div style={{ textAlign: 'right' }}>
                    <Typography.Text style={{ color: 'red' }}>
                      {Fixed2(clientLedger.data?.summary_amount.total_dr)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={2} colSpan={2}>
                  <div style={{ textAlign: 'left' }}>
                    <Typography.Text style={{ color: 'green', marginLeft: 2 }}>
                      {Fixed2(clientLedger.data?.summary_amount.total_cr)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={3} />
              </Table.Summary.Row>
            );
        }}
      />
    </div>
  ) : (
    <></>
  );

  // @HANDLE PRINT WITH HEADER FOOTER

  const print_content = (
    <div>
      <CommonViewReport
        children={ledger_content}
        printRef={componentRef}
        extraInfo={{
          address: clientDetails?.client_address || '',
          email: clientDetails?.client_email || '',
          mobile: clientDetails?.client_mobile || '',
          name: clientDetails?.client_name || '',
          date:
            date_range_value
              ?.split('&')
              ?.map((param) => dayjs(param.split('=')[1])?.format('DD-MM-YYYY'))
              ?.join(' to ') || '',
        }}
        title={{ info_title: 'Client Details', title: 'Client Ledger' }}
      />
    </div>
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Client_Ledger_${date_range_value}`,
    pageStyle: landscapePageStyle,
    removeAfterPrint: true,
  });

  return (
    <>
      {/* @LEDGER CONTENT HERE */}
      <div>
        {!id && <BreadCrumb arrOfOption={['Report', 'Client Ledger']} />}
        <Space style={{ marginBottom: '1rem' }}>
          <Button type='primary' onClick={handlePrint}>
            <PrinterOutlined />
            Print
          </Button>
          <Button
            type='primary'
            onClick={() => {
              if (clientLedger?.data?.ledgers.length)
                fetchExcel({
                  client_id: excelQuery.Id,
                  query: excelQuery.query,
                  excelApiName: 'client_ledger_excel',
                  excelName: `client_ledger_${excelDateRangeFormat(date_range_value as string)}`,
                });
            }}
          >
            <FileTextOutlined />
            Excel Report
          </Button>
        </Space>

        <Row justify={'space-between'}>
          <Col>
            <Form layout='vertical' form={form} onFinish={clientLedgerOnFinish}>
              <Row gutter={16}>
                <SelectClientsOnly
                  required
                  name='client_id'
                  size={10}
                  mdSize={10}
                  smSize={10}
                  xsSize={24}
                  label='Select Client'
                  placeholder='Select Client'
                  disabled={id ? true : false}
                  offDropDown
                />
                <Col span={6} xs={20} sm={10} md={10} lg={12}>
                  <Form.Item
                    label='Date Range'
                    name='date_range'
                    rules={[{ required: true, message: `Date Range is required!` }]}
                  >
                    <DatePicker.RangePicker
                      presets={rangePresets}
                      format={'DD-MM-YYYY'}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col lg={2} md={2} sm={4} xs={24}>
                  <FormButton
                    label='Search'
                    icon
                    textAlign='left'
                    loading={clientFetchLoading || clientLoading}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          {clientDetails && (
            <Col className='p-5 '>
              <FormHeaderTitle title='Client Details' />
              <Table
                size='small'
                bordered
                rowKey={(e) => e.key}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
                dataSource={clientInfoData}
                columns={clientColumn}
                showHeader={false}
                pagination={pagination(clientInfoData.length)}
                locale={{ emptyText: ' ' }}
                loading={{
                  spinning: clientFetchLoading || clientLoading,
                  indicator: loadingIndicator,
                }}
              />
            </Col>
          )}
        </Row>
        {ledger_content}
      </div>

      {print_content}
    </>
  );
}

export default ClientLedgers;
