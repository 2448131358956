import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Fixed2,
  TableParams,
  handleTableChange,
  rangePresets,
} from "../../../../common/utils/common.utils";
import { FormButton, FormInputItem } from "../../../../components/common/FormItem/FormItems";
import { SelectAirline } from "../../../../components/common/FormItem/SelectCustomFeilds";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import { useLazyGetExcelQuery } from "../../ledgers/endpoints/ledgerEndpoints";
import { useLazyGetAirlineWiseSalesReportQuery } from "../endpoints/AirlineWiseSalesReportEndpoints";
import {
  IAirlineWiseSalesReportFormDataType,
  IAirlineWiseSalesReportFormDataTypes,
} from "../types/AirlineWiseSalesReportTypes";
import AirlineWiseSalesReportColumn from "../utils/AirlineWiseSalesReportColumn";
import { useLocation } from "react-router";
import { excelDateRangeFormat } from "../../../../common/utils/excelDateRangeFormat";

const AirlineWiseSalesReport = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [paramData, setParamData] = useState<{
    date_range: string;
    id: number | string;
  }>();
  const [form] = Form.useForm();
  const to_date = dayjs().format("YYYY-MM-DD");
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: "",
    query: "",
  });
  const [fetchAirlineWiseSales, { data, isLoading, isFetching }] =
    useLazyGetAirlineWiseSalesReportQuery();

  let clientPriceSum: number = Number(data?.data?.airticket_client_price || 0);
  let purchasePriceSum: number = Number(data?.data?.airticket_purchase_price || 0);
  let totalProfitSum: number = clientPriceSum - purchasePriceSum;

  const [dataRange, setDateRange] = useState(`from_date=${to_date}&to_date=${to_date}`);

  const onFinish = async (values: IAirlineWiseSalesReportFormDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    setDateRange(date_range);

    const body: IAirlineWiseSalesReportFormDataTypes = {
      date_range: date_range,
      airline_id: values.airline_id,
    };
    await fetchAirlineWiseSales({
      airline_id: body.airline_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      Id: body.airline_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setParamData({
      date_range: date_range,
      id: "",
    });
  };

  useEffect(() => {
    if (searchParams && location.search) {
      const formDate = searchParams.get("from_date");
      const toDate = searchParams.get("to_date");
      const id = searchParams.get("airline_id");
      form.setFieldValue("date_range", [dayjs(formDate), dayjs(toDate)]);
      form.setFieldValue("airline_id", Number(id));
      form.submit();
    } else {
      const date_range = `from_date=${to_date}&to_date=${to_date}`;
      form.setFieldValue("date_range", [dayjs(), dayjs()]);
      form.setFieldValue("airline_id", "all");
      fetchAirlineWiseSales({
        airline_id: "all",
        query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
      });
      setExcelQuery({
        Id: "all",
        query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
      });
      setParamData({
        date_range: date_range,
        id: "",
      });
    }
  }, []);

  //pagination--start---
  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: "0" | "1";
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: "0", pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ["20", "50", "100", "200", "500"],
      pageSize: 20,
      total: data?.count,
    },
  });

  const LastPage = Math.ceil(Number(data?.count || 0) / (tableParams?.pagination?.pageSize || 0));

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: data?.count },
    }));
  }, [data?.count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IAirlineWiseSalesReportFormDataType = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body: IAirlineWiseSalesReportFormDataTypes = {
      date_range: date_range,
      airline_id: values.airline_id,
    };

    fetchAirlineWiseSales({
      airline_id: body.airline_id,
      query: `${query}&${body.date_range}`,
    });

    setExcelQuery({
      Id: body.airline_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Airline_Wise_Sales_Report`,
    pageStyle: "",
  });

  const handleSearch = (e: any) => {
    const search = e.target.value;

    if (search)
      fetchAirlineWiseSales({
        query: `?search=${search}&${dataRange}`,
        airline_id: "all",
      });
  };

  // @HANDLE CONTENTS
  const jsx_com = data?.data?.result?.length ? (
    <div>
      <FormHeaderTitle title="Airline Wise Sales Report" />
      <Table
        size="small"
        bordered
        columns={AirlineWiseSalesReportColumn(queryData, paramData)}
        dataSource={data?.data?.result}
        pagination={data.count !== undefined && data.count > 20 ? tableParams.pagination : false}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
        summary={(_) => {
          if (tableParams.pagination?.current === LastPage)
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={1} colSpan={8}>
                    <div
                      style={{
                        color: "green",
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>Total: </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        color: "green",
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>{Fixed2(clientPriceSum)}</Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        color: "green",
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>{Fixed2(purchasePriceSum)}</Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <div
                      style={{
                        color: "green",
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>{Fixed2(totalProfitSum)}</Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
        }}
      />
    </div>
  ) : (
    <></>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "Airline Wise Sales Report" }}
        extraInfo={{
          date:
            dataRange
              ?.split("&")
              ?.map((param) => dayjs(param.split("=")[1])?.format("DD-MM-YYYY"))
              ?.join(" to ") || "",
        }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb arrOfOption={["Reports", "Airline Wise Sales Report"]} />
      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type="primary"
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: "sales/airlineWiseReport",
              excelName: `airline_wise_sales_report_${excelDateRangeFormat(dataRange as string)}`,
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row justify={"space-between"} align={"middle"}>
          <Col>
            <Row gutter={16}>
              <SelectAirline name="airline_id" label="Select Airline" size={8} showAll />
              <Col span={6} xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  label="Date Range"
                  name="date_range"
                  rules={[{ required: true, message: ` Date Range is required!` }]}
                >
                  <DatePicker.RangePicker
                    presets={rangePresets}
                    format={"DD-MM-YYYY"}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col lg={2}>
                <FormButton label="Search" icon textAlign="left" loading={isLoading} />
              </Col>
            </Row>
          </Col>

          <Col style={{ marginTop: 10 }}>
            <FormInputItem
              name={"hotel_name"}
              size={24}
              placeholder="Search by ticket"
              onBlur={(e) => handleSearch(e)}
            />
          </Col>
        </Row>
      </Form>

      {(isLoading || isFetching) && <LoadingIndicator />}

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default AirlineWiseSalesReport;
