import { createSlice } from '@reduxjs/toolkit';
import lib from '../utils/lib';

const initialState = {
  branch: lib.getLocalStorage('__branch_id') || 'all',
};

const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    setBranch: (state, action) => {
      state.branch = action.payload;
      lib.setLocalStorage('__branch_id', action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(branchSlice.actions.setBranch, (state, action) => {
      state.branch = action.payload;
      lib.setLocalStorage('__branch_id', action.payload);
    });
  },
});

export const { setBranch } = branchSlice.actions;
export default branchSlice.reducer;
