import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Card, ConfigProvider, Descriptions, Row, Typography, theme } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { Col } from 'antd/lib/grid';
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { useAdvanceReturnDetailsQuery } from '../api/endpoints/advanceEndpoints';
import { invoiceViewStyle } from '../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';
import { Container } from '../../Money_Receipt/Components/MoneyReceiptDesign';
import { ReceiptHeader, TitleCenter } from '../../../common/Invoice/InvoiceHeader';
import NumToWord from '../../../components/number_to_words/NumToWord';
import { FormatDatePrint } from '../../../common/utils/common.utils';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

type Props = {};

const AdvanceReturnView = (props: Props) => {
  const { id } = useParams();

  const { data } = useAdvanceReturnDetailsQuery(Number(id));
  const vendorData = data?.data;
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Vendor Advance Return`,
  });
  return (
    <>
      <BreadCrumb arrOfOption={['Advance Return Details']} />
      <Row justify={'space-between'}>
        <Col lg={3} style={{ marginBottom: '1rem' }}>
          <Link to={'/vendors/advance'}>
            <Button type='primary' icon={<ArrowLeftOutlined />}>
              Return List
            </Button>
          </Link>
        </Col>

        <Col>
          {' '}
          <Button type='primary' onClick={handlePrint}>
            <PrinterOutlined />
            Print
          </Button>
        </Col>
      </Row>

      <div style={invoiceViewStyle}>
        <ConfigProvider
          theme={{
            algorithm: theme.defaultAlgorithm,
          }}
        >
          <Container
            style={{
              width: '8.27in',
              margin: '0 auto',
              background: '#fff',
              padding: '14px',
            }}
          >
            <div ref={componentRef} style={{ padding: '0.3in' }}>
              <header>
                <Row justify={'space-between'} align='middle'>
                  <ReceiptHeader />
                </Row>

                <TitleCenter title='advance return' />
              </header>

              <Row justify={'space-between'} style={{ margin: '30px 0' }}>
                <Typography.Text
                  style={{
                    fontSize: '14px',
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  Voucher No: {vendorData?.advr_vouchar_no}
                </Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: '14px',
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  Date : {vendorData?.return_date ? FormatDatePrint(vendorData?.return_date) : ''}
                </Typography.Text>
              </Row>

              <section>
                <Row justify={'space-between'}>
                  <Col>
                    <Typography.Text
                      style={{
                        fontSize: '14px',
                        display: 'block',
                        marginTop: '10px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      Payment with thanks from:
                      <span
                        style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                        className='underline'
                      >
                        {vendorData?.vendor_name}
                      </span>
                    </Typography.Text>

                    <Row justify={'space-between'} style={{ marginTop: '10px' }}>
                      <Typography.Text
                        style={{
                          fontSize: '14px',
                          fontFamily: "'Source Sans Pro', sans-serif",
                        }}
                      >
                        For the purpose of :{' '}
                        <span className='underline'> {vendorData?.return_note} </span>
                      </Typography.Text>
                    </Row>
                  </Col>

                  <Col>
                    <Typography.Text
                      style={{
                        fontSize: '14px',
                        display: 'block',
                        marginTop: '10px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      Payment Amount :{' '}
                      <span className='underline'> {vendorData?.advance_amount}</span>
                    </Typography.Text>

                    <Typography.Text
                      style={{
                        fontSize: '14px',
                        display: 'block',
                        marginTop: '10px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <span className='underline'>
                        {vendorData?.advance_amount && (
                          <NumToWord number={Number(vendorData?.advance_amount)} />
                        )}
                      </span>
                    </Typography.Text>
                  </Col>
                </Row>
              </section>

              <Row style={{ marginTop: 50 }} justify='space-between' align={'middle'}>
                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      background: 'black',
                      margin: '0px',
                      width: '150px',
                      height: '1px',
                    }}
                  />
                  <Typography.Text
                    style={{
                      fontSize: '14px',
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    Customer Signature
                  </Typography.Text>
                </Col>

                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      background: 'black',
                      margin: '0px',
                      width: '150px',
                      height: '1px',
                    }}
                  />
                  <Typography.Text
                    style={{
                      fontSize: '14px',
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    Authority Signature
                  </Typography.Text>
                </Col>
              </Row>
            </div>
          </Container>
        </ConfigProvider>
      </div>
    </>
  );
};

export default AdvanceReturnView;
