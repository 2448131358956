import { Button, ConfigProvider, Form, Space, Table, theme } from 'antd';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import {
  ICountryWiseReportApiDataType,
  ICountryWiseReportFormDataType,
} from '../types/countryWiseReportTypes';
import dayjs from 'dayjs';
import { useLazyGetCountryWiseReportQuery } from '../endpoints/countryWiseReportEndpoints';
import { countryWiseReportColumn } from '../utils/countryWiseReportColumn';
import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { useState, useRef, useEffect } from 'react';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { pagination } from '../../../../common/utils/common.utils';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import { excelDateRangeFormat } from '../../../../common/utils/excelDateRangeFormat';

const CountryWiseReport = () => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });
  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
  }, []);
  const [fetchCountryWiseReport, { data, isLoading }] = useLazyGetCountryWiseReportQuery({
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const dataToReturn: ICountryWiseReportApiDataType[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return {
        ...cache,
        data: dataToReturn,
      };
    },
  });
  const onFinish = async (values: ICountryWiseReportFormDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    setDate_range_value(date_range);
    const body: ICountryWiseReportFormDataType = {
      date_range: date_range,
      country_id: values.country_id,
    };

    await fetchCountryWiseReport(body);
    setExcelQuery({
      Id: body.country_id.toString(),
      query: `?${body.date_range}`,
    });
  };
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Country_Wise_Report`,
  });

  const toDate = dayjs().format('YYYY-MM-DD');
  useEffect(() => {
    form.setFieldValue('country_id', 'all');

    const date_range = `from_date=${toDate}&to_date=${toDate}`;
    const body: ICountryWiseReportFormDataType = {
      date_range: date_range,
      country_id: 'all',
    };

    fetchCountryWiseReport(body);
    setExcelQuery({
      Id: body.country_id.toString(),
      query: `?${body.date_range}`,
    });
  }, []);

  // @HANDLE CONTENTS
  const jsx_com = (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <Table
        size='small'
        bordered
        pagination={pagination(data.length)}
        columns={countryWiseReportColumn()}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
        dataSource={data}
        scroll={{ x: true }}
      />
    </ConfigProvider>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Country Wise Report' }}
        extraInfo={{
          date:
            date_range_value
              ?.split('&')
              ?.map((param) => dayjs(param.split('=')[1])?.format('DD-MM-YYYY'))
              ?.join(' to ') || '',
        }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Country Wise Report']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: 'countryWiseReport',
              excelName: `Country_Wise_Report_${excelDateRangeFormat(date_range_value as string)}`,
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader date_range required showCountry reports_title='Country Wise Report' />
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default CountryWiseReport;
