import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useReactToPrint } from "react-to-print";
import {
  Fixed2,
  TableParams,
  handleTableChange,
  pagination,
  rangePresets,
} from "../../../../common/utils/common.utils";
import { FormButton } from "../../../../components/common/FormItem/FormItems";
import { SelectCombined } from "../../../../components/common/FormItem/SelectCustomFeilds";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import { getDataWithIndex } from "../../../../helpers/getDataWithIndex";
import { useLazyGetSingleCombineClientsQuery } from "../../../Client/combined_clients/api/endpoints/clientCombineEndpoints";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import {
  useLazyGetAllCombinedLedgerQuery,
  useLazyGetExcelQuery,
} from "../endpoints/ledgerEndpoints";
import { IClientLedgerFormData } from "../types/clientLedgerTypes";
import { CombinedLedger } from "../utils/CombinedLedger.utils";
import { landscapePageStyle } from "./ClientLedgers";
import { excelDateRangeFormat } from "../../../../common/utils/excelDateRangeFormat";

type Props = {};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function CombinedLedgerReport({}: Props) {
  const query = useQuery();
  const queryId = query.get("combine_id");

  useEffect(() => {
    if (queryId) {
      form.setFieldValue("client_id", Number(queryId));
      form.setFieldValue("date_range", [dayjs().subtract(1, "month"), dayjs()]);
      form.submit();
    } else {
      form.setFieldValue("date_range", [dayjs(), dayjs()]);
    }
  }, [queryId]);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [combID, setCombId] = useState<string>(id as string);
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: "",
    query: "",
  });
  const [fetchExcel] = useLazyGetExcelQuery();

  useEffect(() => {
    if (id) {
      form.setFieldValue("date_range", [dayjs().subtract(1, "months"), dayjs()]);
      form.setFieldValue("client_id", Number(id));
    }
    id && form.submit();
  }, [id]);

  const [
    fetchcombibedLedger,
    {
      data: combibedLedger,
      isLoading: clientFetchLoading,
      isFetching,
      isSuccess: clientFetchSuccess,
    },
  ] = useLazyGetAllCombinedLedgerQuery();

  const [fetchSingleCombinedDetails, { data: combinedDetails, isLoading: isCombinedLoading }] =
    useLazyGetSingleCombineClientsQuery();

  const combinedLedgerOnFinish = async (values: IClientLedgerFormData) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;

    const body: IClientLedgerFormData = {
      client_id: id ? Number(id) : (values.client_id as number),
      date_range: date_range,
    };

    setDate_range_value(date_range);
    fetchcombibedLedger({
      client_id: body.client_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    }),
      combID && fetchSingleCombinedDetails(Number(combID));
    setExcelQuery({
      Id: body.client_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  //pagination
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(combibedLedger?.count || 0);
  }, [combibedLedger]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: "0" | "1";
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: "0", pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ["20", "50", "100", "200", "500"],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IClientLedgerFormData = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body: IClientLedgerFormData = {
      client_id: id ? Number(id) : (values.client_id as number),
      date_range: date_range,
    };

    fetchcombibedLedger({
      client_id: body.client_id,
      query: `${query}&${body.date_range}`,
    });
    setExcelQuery({
      Id: body.client_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Combined_Ledger_${date_range_value}`,
    pageStyle: landscapePageStyle,
  });

  const combinedInfoData = [
    {
      key: "1",
      name: "Name",
      value: combinedDetails?.data && combinedDetails?.data?.combine_name,
    },
    {
      key: "2",
      name: "Email",
      value: combinedDetails?.data && combinedDetails?.data?.combine_email,
    },
    {
      key: "3",
      name: "Mobile",
      value: combinedDetails?.data && combinedDetails?.data?.combine_mobile,
    },
  ];
  const combinedColumn = [
    {
      dataIndex: "name",
      key: "name",
      width: "150px",
    },
    {
      dataIndex: "value",
      key: "value",
      width: "120px",
      render: (text: string, record: any) => {
        if (record.key === "5") {
          return (
            <span
              style={{
                color:
                  Number(combinedDetails?.data?.combine_lastbalance_amount) > 0 ? "green" : "red",
              }}
            >
              {text}
            </span>
          );
        } else {
          return text;
        }
      },
    },
  ];

  const LastPage = Math.ceil(
    Number(combibedLedger?.count || 0) / (tableParams?.pagination?.pageSize || 0)
  );

  // -------//-----------//-------HANDLE PRINT-----------//--------------//----
  const ledger_content = combibedLedger?.data?.ledgers?.length ? (
    <>
      <Typography.Text style={{ margin: 10 }}>You have total: {count} rows</Typography.Text>
      <Table
        tableLayout="fixed"
        style={{ width: "100%" }}
        size="small"
        bordered
        className="invoiceBillingTable"
        rowClassName={"invoiceBillingTd"}
        pagination={count > 20 ? tableParams.pagination : false}
        dataSource={getDataWithIndex(combibedLedger?.data.ledgers)}
        columns={CombinedLedger(queryData)}
        // scroll={{ x: 400 }}
        loading={{
          spinning: clientFetchLoading || isFetching,
          indicator: loadingIndicator,
        }}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
        summary={(_) => {
          if (tableParams.pagination?.current === LastPage)
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={6}>
                  <div className="">
                    <Typography.Text strong>Total: </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1} colSpan={4}>
                  <div style={{ textAlign: "right" }}>
                    <Typography.Text style={{ color: "red" }}>
                      {Fixed2(combibedLedger.data?.summary_amount.total_dr)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={2} colSpan={4}>
                  <div style={{ textAlign: "left" }}>
                    <Typography.Text style={{ color: "green", marginLeft: 2 }}>
                      {Fixed2(combibedLedger.data?.summary_amount.total_cr)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                {/* <Table.Summary.Cell index={3} colSpan={3} /> */}
              </Table.Summary.Row>
            );
        }}
      />{" "}
    </>
  ) : (
    <></>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const combined_info = combinedDetails?.data;
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={ledger_content}
        printRef={componentRef}
        extraInfo={{
          address: combined_info?.combine_name || "",
          email: combined_info?.combine_email || "",
          mobile: combined_info?.combine_mobile || "",
          name: combined_info?.combine_name || "",
          date:
            date_range_value
              ?.split("&")
              ?.map((param) => dayjs(param.split("=")[1])?.format("DD-MM-YYYY"))
              ?.join(" to ") || "",
        }}
        title={{ info_title: "Comb Vendor Details", title: "Combined Ledger" }}
      />
    </div>
  );
  return (
    <div>
      {!id && <BreadCrumb arrOfOption={["Report", "Combined Ledger"]} />}
      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type="primary"
          onClick={() => {
            if (combibedLedger?.data?.ledgers?.length)
              fetchExcel({
                client_id: excelQuery.Id,
                query: excelQuery.query,
                excelApiName: "combined_ledger_excel",
                excelName: `Combined_ledger_${excelDateRangeFormat(date_range_value as string)}`,
              });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>

      <Row justify={"space-between"} gutter={[16, 16]}>
        <Form layout="vertical" form={form} onFinish={combinedLedgerOnFinish}>
          <Row gutter={16}>
            <SelectCombined
              required
              name="client_id"
              size={10}
              disabled={id ? true : false}
              label="Select Combined"
              placeholder="Select Client"
              setCombId={setCombId}
              offDropDown
            />
            <Col span={6} xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                label="Date Range"
                name="date_range"
                rules={[{ required: true, message: ` Date Range is required!` }]}
              >
                <DatePicker.RangePicker
                  presets={rangePresets}
                  format={"DD-MM-YYYY"}
                  allowClear={false}
                  // onChange={onDateRangeChange}
                />
              </Form.Item>
            </Col>
            <Col lg={2} xs={2} sm={2} md={2}>
              <FormButton
                label="Search"
                icon
                textAlign="left"
                loading={clientFetchLoading || isFetching}
              />
            </Col>
          </Row>
        </Form>
        {combinedDetails?.data && (
          <Col lg={8} xl={8} className="p-5">
            <FormHeaderTitle title="Combined Reports Details" />
            <Table
              className="invoiceBillingTable"
              bordered
              rowClassName={"invoiceBillingTd"}
              showHeader={false}
              size="small"
              pagination={pagination(combinedInfoData.length)}
              columns={combinedColumn}
              dataSource={combinedInfoData}
              locale={{ emptyText: " " }}
              loading={{
                spinning: isCombinedLoading,
                indicator: loadingIndicator,
              }}
            />
          </Col>
        )}
      </Row>

      <div className="p-5 my-20 ">
        {/* @LEDGET CONTENT */}
        {ledger_content}
      </div>

      {/* @PRINT CONTENT*/}
      {print_content}
    </div>
  );
}

export default CombinedLedgerReport;
